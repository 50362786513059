<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import LitInput from '@/js/components/Base/LitInput.vue';
import DatePicker from '@/js/components/DatePicker.vue';

import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import StepButtons from '@/js/views/common/StepButtons.vue';
import gtm from '@/js/services/gtm';
import { scrollToError } from '@/js/utils.ts';
import ChangeRequestFormsOfferModal from '@/js/components/Modals/ChangeRequestFormsOfferModal.vue';

const store = useChangeRequestStore();
const { validateField, validateFields } = store;
const { documents } = storeToRefs(store);

const route = useRoute();
const router = useRouter();

const validating = ref(false);
const showFormsOfferModal = ref(false);

const warning = ref<{
  idCard: string | undefined
  secondDocument: string | undefined
}>({
  idCard: undefined,
  secondDocument: undefined,
});

const hasSecondDocumentStandardFormat = computed(() => {
  const drivingLicence = /^([aeiou])[a-z](\s)*\d{6}$/i;
  const passport = /^[1-9]\d{7}$/;

  const secondDocumentNumber = documents.value.secondDocument.number.value;

  return (secondDocumentNumber && drivingLicence.test(secondDocumentNumber))
    || (secondDocumentNumber && passport.test(secondDocumentNumber));
});

const hasIdCardStandardFormat = computed(() => {
  const standardFormat = /^\d{9}$/;

  return documents.value.idCard.number.value ? standardFormat.test(documents.value.idCard.number.value) : false;
});

function checkSecondDocumentFormat () {
  if (hasSecondDocumentStandardFormat.value === false) {
    warning.value.secondDocument = 'Vyplnili jste číslo dokladu, které není '
    + 'v obvyklém formátu. Prosím zkontrolujte si číslo dokladu.';
  } else {
    warning.value.secondDocument = '';
  }
}

function checkIdCardFormat () {
  if (!hasIdCardStandardFormat.value) {
    warning.value.idCard = 'Vyplnili jste číslo občanského průkazu, které není '
    + 'v obvyklém formátu. Prosím zkontrolujte si číslo dokladu.';
  } else {
    warning.value.idCard = '';
  }
}

function offerForms () {
  showFormsOfferModal.value = true;
}

async function handleSubmit () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    await router.push({
      name: 'changeRequest.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zmena-smlouvy');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation clientsStatementError: ${e}`);
  } finally {
    validating.value = false;
  }
}
</script>

<template>
  <div class="container--sm">
    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate, readOnly }"
      namespace="identityDocumentChange"
      field-path="idCard.number"
      :debounce="0"
      @validate="validateField"
    >
      <LitInput
        class="mb-20"
        label="Číslo OP"
        name="idCardNumber"
        :disabled="readOnly"
        :read-only="readOnly"
        :warning="warning.idCard"
        :model-value="value"
        :errors="errors"
        :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
        @update:model-value="input"
        @blur="validate($event.target.value);checkIdCardFormat();"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate, readOnly }"
      namespace="identityDocumentChange"
      field-path="idCard.validUntil"
      dusk="id-card-validity"
      :debounce="0"
      @validate="validateField"
    >
      <DatePicker
        v-if="!readOnly"
        class="mb-20"
        label="Platný do"
        name="idValidUntil"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
        @change="validate"
      />
      <LitInput
        v-else
        class="mb-20"
        label="Platný do"
        name="idValidUntil"
        :disabled="readOnly"
        :read-only="readOnly"
        :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate, readOnly }"
      namespace="identityDocumentChange"
      field-path="idCard.issuedBy"
      :debounce="0"
      @validate="validateField"
    >
      <LitInput
        class="mb-30"
        label="Vydal"
        name="IDIssued"
        :disabled="readOnly"
        :read-only="readOnly"
        :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
        @blur="validate($event.target.value)"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate }"
      namespace="identityDocumentChange"
      field-path="secondDocument.number"
      :debounce="0"
      @validate="validateField"
    >
      <LitInput
        class="mb-20"
        label="Číslo dokladu"
        name="secondDocumentNumber"
        :warning="warning.secondDocument"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
        @blur="validate($event.target.value);checkSecondDocumentFormat();"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate }"
      namespace="identityDocumentChange"
      field-path="secondDocument.validUntil"
      dusk="second-document-validity"
      :debounce="0"
      @validate="validateField"
    >
      <DatePicker
        class="mb-20"
        label="Platný do"
        name="secondDocumentValidUntil"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
        @change="validate"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate }"
      namespace="identityDocumentChange"
      field-path="secondDocument.issuedBy"
      :debounce="0"
      @validate="validateField"
    >
      <LitInput
        class="mb-20"
        label="Vydal"
        name="secondDocumentIssuedBy"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
        @blur="validate($event.target.value)"
      />
    </ServerValidatedFormField>

    <hr class="separator my-30">

    <div class="flex flex-between">
      <StepButtons
        namespace="changeRequest"
        previous-step="clientData"
        @submit="offerForms"
      />
    </div>

    <ChangeRequestFormsOfferModal
      v-model="showFormsOfferModal"
      :forms="store.changeSettings.involvedForms.value"
      @close="showFormsOfferModal = false"
      @finish="handleSubmit"
    />
  </div>
</template>
