<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { storeToRefs } from 'pinia';
import BeforeStart from '@/js/components/RequestChange/BeforeStart.vue';
import { useGeneralStore } from '@/js/stores';

import gtm from '@/js/services/gtm';
import { scrollToError } from '@/js/utils';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import StepButtons from '@/js/views/common/StepButtons.vue';

const generalStore = useGeneralStore();
const route = useRoute();
const router = useRouter();

const store = useChangeRequestStore();
const { validateField, validateFields } = store;
const { beforeStart } = storeToRefs(store);

const validating = ref(false);

async function handleSubmit () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        'beforeStart.s1',
        'beforeStart.s2',
        'beforeStart.s3',
        'beforeStart.isTiedAgent',
        'beforeStart.distributionName',
        'beforeStart.distributor.firstName',
        'beforeStart.distributor.lastName',
        'beforeStart.distributor.email',
        'beforeStart.s1',
        'beforeStart.s1',
        'beforeStart.s1',
        'beforeStart.meetingAddress.street',
        'beforeStart.meetingAddress.streetNumber',
        'beforeStart.meetingAddress.city',
        'beforeStart.meetingAddress.zip',
        'beforeStart.meetingAddress.country',
      ],
    });

    validating.value = false;

    await router.push({
      name: `changeRequest.clientData`,
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('identifikace-ucastnika');
  } catch (e) {
    if (beforeStart.value.isTiedAgent.value && typeof beforeStart.value.meetingAddress !== 'undefined') {
      beforeStart.value.meetingAddress.setManually.value = true;
    }
    scrollToError();

    console.warn(`There was a validation clientDataError: ${e}`);
  } finally {
    validating.value = false;
  }
}

onMounted(() => {
  const formType = route.query?.form as string;

  if (route.query.form && !store.changeSettings.involvedForms.value.includes(formType)) {
    store.changeSettings.involvedForms.value.push(formType);
  }
});
</script>

<template>
  <div>
    <section class="benefits bg-blue pt-30 pb-10" />

    <div class="container--sm mt-20">
      <BeforeStart
        :display-address-field="generalStore.isTiedAgent.value"
        :show-meeting-address-title="store.beforeStart.meetingAddress?.setManually.value"
        :validate-fnc="validateField"
      />

      <hr class="my-30">

      <div class="flex flex-center">
        <StepButtons
          namespace="changeRequest"
          button-position="flex-center"
          :display-previous-step-button="false"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>
