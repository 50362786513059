<template>
  <div dusk="thank-you">
    <div class="benefits bg-blue pt-30 pb-10" />

    <div class="container--sm my-30">
      <TheHeading>Děkujeme vám za sjednání penzijního spoření</TheHeading>
    </div>

    <div class="container--sm mb-20">
      <h3 class="my-20">
        Nyní si stáhněte a vytiskněte smlouvu
      </h3>

      <p
        v-if="store.beforeStart.isTiedAgent.value"
        class="mb-20"
      >
        Tato PDF smlouva a&nbsp;Záznam z&nbsp;jednání byly zaslány na e-mail klienta.
        Vytiskněte prosím 2x PDF smlouvu a&nbsp;Záznam z&nbsp;jednání, podepište (obchodník&nbsp;+&nbsp;klient).

        Jednu sadu dokumentů předejte klientovi a&nbsp;druhou sadu dokumentů neprodleně zašlete na adresu:
      </p>

      <p
        v-else
        class="mb-20"
      >
        Tato PDF smlouva byla zaslána na e-mail klienta a&nbsp;obchodníka.
        Vytiskněte prosím 3x PDF smlouvu, podepište (obchodník + klient)
        a&nbsp;neprodleně zašlete na adresu:
      </p>

      <p class="mb-20">
        ČSOB Penzijní společnost, a. s.<br>
        Collinova 573<br>
        500 03  Hradec Králové
      </p>

      <p
        v-show="showPensionCompanyNotice"
        class="py-20"
      >
        Vyplňte prosím s klientem také <strong>výpověď doplňkového penzijního spoření</strong>
        se žádostí o převod prostředků. Výpověď se žádostí o převod musí být úředně ověřená
        (matrika/Czech Point/notář) a odeslaná do původní penzijní společnosti.
      </p>

      <p
        v-show="showTransformationFundNotice"
        class="py-20"
      >
        Vyplňte prosím s klientem také <strong>výpověď doplňkového penzijního spoření</strong>
        se žádostí o převod prostředků.
      </p>

      <LitAlert
        v-if="store.beforeStart.isTiedAgent.value && !showContractHiddenAlert"
        alert-type="warning"
        class="mb-20"
      >
        <strong>Dokumentace již není odesílána na e-mail obchodníka.</strong> V případě ukončení procesu/zavření tohoto okna,
        už nebude možné dokumentaci znovu vytisknout.
      </LitAlert>

      <div v-if="loading">
        <div class="flex flex-center mb-20 flip">
          <img
            :src="getIconUrl('icon-loader_blue')"
            alt="Načítání..."
            class="spin"
          >
        </div>
        <div class="text-loading text-center text-bold">
          Nahráváme ke stažení uzavřenou smlouvu v PDF, chvíli strpení
        </div>
      </div>

      <LitAlert
        v-if="showContractHiddenAlert"
        class="mb-20"
        alert-type="info"
        dusk="contract-sending-failure"
      >
        <div>
          Odkaz ke stažení smlouvy již není platný nebo jste ji stáhli již {{ maxDownloadCount }}x.
          Smlouvu však <span v-if="!store.beforeStart.isTiedAgent.value">máte Vy i klient</span>
          <span v-else>má klient</span> v e-mailu.
        </div>
      </LitAlert>

      <div :class="['flex', 'flex-center', showPensionCompanyNotice || showTransformationFundNotice ? 'gap-1' : '']">
        <div v-if="contractPdf && remainingAttempts > 0">
          <LitButton
            :href="contractPdf.link"
            as="a"
            download
            @click="downloaded++"
          >
            <template #before>
              <Download :size="20" />
            </template>

            <template #default>
              <div class="flex flex-dir-column">
                <span class="text-uppercase">stáhnout smlouvu</span>
                <span class="text-sm text-light"><span v-if="store.beforeStart.isTiedAgent.value">vč. záznamu z jednání, </span>PDF: {{ contractPdf.size }} kB</span>
              </div>
            </template>
          </LitButton>
        </div>

        <div v-show="showPensionCompanyNotice">
          <LitButton
            href="/storage/documents/vypoved_DPS_se_zadosti_o_prevod_k_CSOBPS_512.pdf"
            as="a"
            download
          >
            <template #before>
              <Download :size="20" />
            </template>

            <template #default>
              <div class="flex flex-dir-column">
                <span class="text-uppercase">Stáhnout výpověď</span>
                <span class="text-sm font-weight-light">PDF: 257 kB</span>
              </div>
            </template>
          </LitButton>
        </div>

        <div v-show="showTransformationFundNotice">
          <LitButton
            href="/storage/documents/vypoved_penzijniho_pripojisteni_514.pdf"
            as="a"
            download
          >
            <template #before>
              <Download :size="20" />
            </template>

            <template #default>
              <div class="flex flex-dir-column">
                <span class="text-uppercase">Stáhnout výpověď</span>
                <span class="small">PDF, 97 kB</span>
              </div>
            </template>
          </LitButton>
        </div>
      </div>

      <div
        v-if="contractPdf && remainingAttempts > 0"
        class="mt-30"
      >
        Odkaz ke stažení smlouvy ve formátu PDF je
        <strong>platný do {{ accessTokenValidity && useDateTimeFormat(accessTokenValidity) }}</strong>,
        smlouvu můžete stáhnout {{ remainingAttempts }}x.
      </div>

      <hr class="py-20 mt-20">

      <div class="flex flex-center">
        <LitButton
          variant="outline"
          :to="{ name: 'distribution.home' }"
        >
          Přejít na hlavní rozcestník
        </LitButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { storeToRefs } from 'pinia';

import { Download } from 'lucide-vue-next';
import contractFiles from '@/js/api/contractFiles';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitButton from '@/js/components/Base/LitButton.vue';

import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat';
import { getIconUrl } from '@/js/utils';
import { useDistributionStore } from '@/js/stores';
import { resetState } from '@/js/stores/utils';
import TheHeading from '@/js/components/TheHeading.vue';
import type { File } from '@/js/stores/types';
import env from '@/js/env.ts';

const store = useDistributionStore();
const { submitResponse, contractSettings } = storeToRefs(store);

const loading = ref(true);

const downloaded = ref(0);
const now = ref(new Date());

const accessToken = computed(() => {
  return submitResponse.value.value?.metadata.accessToken;
});

const accessTokenValidity = computed(() => {
  if (!submitResponse.value.value?.metadata.accessTokenValidity) {
    return null;
  }

  return new Date(submitResponse.value.value?.metadata.accessTokenValidity);
});

const isTokenValid = computed(() => {
  if (accessTokenValidity.value) {
    return accessTokenValidity.value > now.value;
  }

  return false;
});

const maxDownloadCount = computed(() => {
  return submitResponse.value.value
    ?.metadata?.maxDownloadCount ?? 0;
});

const remainingAttempts = computed(() => {
  return maxDownloadCount.value - downloaded.value;
});

const showContractHiddenAlert = computed(() => {
  return !isTokenValid.value || remainingAttempts.value <= 0;
});

const showTransformationFundNotice = computed(() => {
  return contractSettings.value.transferExistingRetirementSavings.value === true
    && contractSettings.value.currentContract.type.value === 'transformFund';
});

const showPensionCompanyNotice = computed(() => {
  return contractSettings.value.transferExistingRetirementSavings.value === true
    && contractSettings.value.currentContract.type.value === 'pensionCompany';
});

function clearLocalContractData () {
  resetState(store.$state);
}

onBeforeRouteLeave((to, _from, next) => {
  clearLocalContractData();

  const disallowedPaths = [
    'distribution.contractDocumentsAndConsents',
  ];

  if (to.name && disallowedPaths.includes(to.name as string)) {
    next({ name: 'distribution.home' });
  } else {
    next();
  }
});

const currentTimeInterval = ref<number | null>(null);

function startTimeMeasuring () {
  currentTimeInterval.value = setInterval(() => {
    now.value = new Date();
  }, 1000);
}

const contractPdf = ref<{ link: string, size: number } | null>(null);

function waitForContractPdf () {
  let attempts = 0;

  const interval = setInterval(async () => {
    const contractId = submitResponse.value.value?.metadata.contractId;
    const files: File[] = await contractFiles.index(contractId);

    attempts += 1;

    const fileType = store.beforeStart.isTiedAgent.value ? 'joined_pdf' : 'contract';
    const file = files.find((file) => file.file_type === fileType);

    if (files.length > 0 && file) {
      contractPdf.value = {
        link: `${env.REST_API_URI}/v1/files/${file.id}?token=${accessToken.value}&contractId=${contractId}`,
        size: Math.round(file.file_size / 1024),
      };

      clearInterval(interval);
    } else if (attempts > 10) {
      clearInterval(interval);
    }
  }, 3 * 1000);
}

watch(contractPdf, (newValue) => {
  if (newValue !== null) {
    loading.value = false;
  }
});

watch(isTokenValid, (newValue) => {
  if (!newValue) {
    currentTimeInterval.value && clearInterval(currentTimeInterval.value);
    contractPdf.value = null;
  }
});

onMounted(() => {
  waitForContractPdf();
  startTimeMeasuring();
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.btn-meeting-record {
  padding: 15px 20px 10px !important;
  align-items: center;
  text-transform: initial;

  .icon_justify {
    margin-left: -20px !important;
  }
}

.btn-contract {
  padding: 11px 30px 3px !important;
  align-items: center;
  text-transform: initial;

  .icon_justify {
    margin-left: -30px !important;
  }
}

.small {
  font-size: 14px;
  text-transform: initial;
  font-weight: initial;
}

.thanksAlert {
  margin-bottom: 0;
  font-size: 1.375rem;
}

.grey {
  color: getColor(grey);
}

.download {
  text-decoration: none;
  align-items: center;
}

.rotate {
  transform: rotate(180deg);
}

.big {
  height: 70px;
}

.text-loading {
  color: #092f68;
}
</style>
