<script setup lang="ts">
import { computed, shallowRef, watch } from 'vue';
import { getActivePinia } from 'pinia';
import { get } from 'lodash';
import EmailInput from '@/js/components/EmailInput.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import type { ValidateStoreParamsFnc } from '@/js/stores/utils.ts';
import { syncedStoreField } from '@/js/stores/utils.ts';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import { useSignerTranslation } from '@/js/composables/useSignerTranslation';
import DatePicker from '@/js/components/DatePicker.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import env from '@/js/env.ts';

const props = withDefaults(defineProps<Props>(), {
  namespace: 'changeRequest',
  title: 'Dodatek ke smlouvě podepíše',
  signerTypes: () => [
    { value: 'participant', label: 'účastník' },
    { value: 'legalRepresentative', label: 'zákonný zástupce' },
    { value: 'committee', label: 'opatrovník' },
  ],
});

const activeStore = computed(() => {
  const stores = getActivePinia();

  if (!stores) {
    throw new Error('No active pinia stores found');
  }

  const store = get(stores.state.value, props.namespace);

  if (!store) {
    throw new Error(`No store found for namespace ${props.namespace}`);
  }

  return store;
});

interface Props {
  namespace?: string
  fieldPath?: string
  displayAddressField?: boolean
  validateFnc: ValidateStoreParamsFnc
  title?: string
  signerTypes?: { value: string, label: string }[]
}

const permanentAddressAreaOptions = shallowRef([
  { value: 'cz', label: 'v ČR' },
  { value: 'eu', label: 'v EU' },
]);

const documentTypes = shallowRef([
  { value: 'idCard', label: 'Občanský průkaz' },
  { value: 'passport', label: 'Cestovní pas' },
  { value: 'anotherDoc', label: 'Jiný doklad' },
]);

const permanentAddressArea = computed({
  ...syncedStoreField(activeStore.value, {
    path: `clientData.permanentAddressArea.value`,
  }),
});

const signerDocumentType = computed({
  ...syncedStoreField(activeStore.value, {
    path: `signer.document.type.value`,
  }),
});

const isClientFromCzechia = computed(() => {
  return activeStore.value.clientData.permanentAddressArea.value === 'cz'
    || activeStore.value.clientData.permanentAddressArea.value === null;
});

const isSignerFromCzechia = computed(() => {
  return activeStore.value.signer.permanentAddressArea.value === 'cz'
    || activeStore.value.signer.permanentAddressArea.value === null;
});

const personalIdNumber = computed({
  ...syncedStoreField(activeStore.value, {
    path: 'clientData.personalIdNumber.value',
  }),
});

const birthDate = computed({
  ...syncedStoreField(activeStore.value, {
    path: 'clientData.birthDate.value',
  }),
});

const signer = computed({
  ...syncedStoreField(activeStore.value, {
    path: 'signer.type.value',
  }),
});

const isParticipant = computed(() => {
  return signer.value === 'participant';
});

const personalIdNumberErrors = computed({
  ...syncedStoreField(activeStore.value, {
    path: 'clientData.personalIdNumber.errors',
  }),
});

const label = computed(() => {
  return useSignerTranslation(signer.value, 'accusative');
});

const contractNumber = computed({
  get () {
    return activeStore.value.clientData.contractNumber.value;
  },
  set (value) {
    activeStore.value.clientData.contractNumber.value = value;
  },
});

const isProduction = computed(() => {
  return env.APP_ENV === 'production';
});

watch(permanentAddressArea, (newVal) => {
  switch (newVal) {
    case 'cz':
      personalIdNumber.value = '';
      birthDate.value = null;
      break;

    case 'eu':
      personalIdNumberErrors.value = [];
      break;

    default:
      personalIdNumber.value = '';
  }
});
</script>

<template>
  <div dusk="client-data">
    <h3 class="pt-30">
      {{ title }}
    </h3>

    <ServerValidatedFormField
      v-slot="{ value, input, errors }"
      :namespace="props.namespace"
      :debounce="0"
      :validate-on-input="true"
      field-path="signer.type"
      default-value="participant"
      @validate="validateFnc"
    >
      <LitRadio
        class="mb-20"
        dusk="signer-type"
        name="signer-type"
        :error="errors.length > 0 ? errors[0] : ''"
        :options="props.signerTypes"
        :model-value="value"
        @update:model-value="input"
      />
    </ServerValidatedFormField>

    <h3 class="mt-30">
      Identifikace účastníka, jehož údaje budou upraveny
    </h3>

    <div>
      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="props.namespace"
        field-path="clientData.firstName"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Jméno"
          name="firstName"
          dusk="first-name"
          placeholder="Např. Karel"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, validate, input, errors }"
        :namespace="props.namespace"
        field-path="clientData.lastName"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Příjmení"
          name="lastName"
          dusk="last-name"
          placeholder="Např. Novotný"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        field-path="clientData.permanentAddressArea"
        default-value="cz"
        :namespace="props.namespace"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitRadio
          class="mb-20"
          label="Trvalé bydliště"
          dusk="permanent-address-area"
          name="permanent-address-area"
          :show-inline="true"
          :options="permanentAddressAreaOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="props.namespace"
        field-path="clientData.personalIdNumber"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          name="personal-id"
          dusk="personal-id"
          :label="isClientFromCzechia ? 'Rodné číslo' : 'Číslo pojištěnce zdravotní pojišťovny'"
          :model-value="value"
          :errors="errors"
          date-pick
          @update:model-value="input"
          @blur="validate($event.target.value)"
          @keydown.space.prevent
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, errors, input, validate }"
        :namespace="props.namespace"
        field-path="clientData.birthDate"
        :debounce="0"
        @validate="validateFnc"
      >
        <DatePicker
          v-if="!isClientFromCzechia"
          class="mb-20"
          name="birth-day"
          dusk="birth-date"
          label="Datum narození"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @change="validate"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ input, value, errors, validate }"
        :namespace="props.namespace"
        field-path="clientData.contractNumber"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Číslo smlouvy"
          name="csobContractNumber"
          dusk="csob-contract-number"
          type="number"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
        <div
          v-if="!isProduction"
          class="debug-buttons"
        >
          <button
            type="button"
            @click="contractNumber = '6907502610'"
          >
            <b>[DEBUG] Číslo smlouvy DPS: 6907502610</b>
          </button>
          <button
            type="button"
            @click="contractNumber = '7154897114'"
          >
            <b>[DEBUG] Číslo smlouvy TRF: 7154897114</b>
          </button>
        </div>
      </ServerValidatedFormField>

      <div v-if="!isParticipant">
        <h3 class="mt-30">
          Identifikace {{ label }}, který dodatek podepíše
        </h3>
        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="props.namespace"
          field-path="signer.firstName"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitInput
            class="mb-20"
            label="Jméno"
            name="signerFirstName"
            dusk="signer-first-name"
            placeholder="Např. Karel"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, validate, input, errors }"
          :namespace="props.namespace"
          field-path="signer.lastName"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitInput
            class="mb-20"
            label="Příjmení"
            name="signerLastName"
            dusk="signer-last-name"
            placeholder="Např. Novotný"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          field-path="signer.permanentAddressArea"
          default-value="cz"
          :namespace="props.namespace"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitRadio
            class="mb-20"
            dusk="signer-permanent-address-area"
            name="signerPermanentAddressArea"
            label="Trvalé bydliště"
            :show-inline="true"
            :options="permanentAddressAreaOptions"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="props.namespace"
          field-path="signer.personalIdNumber"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitInput
            class="mb-20"
            name="signerPersonalId"
            dusk="signer-personal-id"
            :label="isSignerFromCzechia ? 'Rodné číslo' : 'Číslo pojištěnce zdravotní pojišťovny'"
            :model-value="value"
            :errors="errors"
            date-pick
            @update:model-value="input"
            @blur="validate($event.target.value)"
            @keydown.space.prevent
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, errors, input, validate }"
          :namespace="props.namespace"
          field-path="signer.birthDate"
          :debounce="0"
          @validate="validateFnc"
        >
          <DatePicker
            v-if="!isSignerFromCzechia"
            class="mb-20"
            name="signerBirthDay"
            dusk="signer-birth-date"
            label="Datum narození"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @change="validate"
          />
        </ServerValidatedFormField>

        <h3 class="mt-30">
          Doklad totožnosti {{ label }}
        </h3>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          field-path="signer.document.type"
          :namespace="namespace"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitSelect
            class="mb-20"
            label="Typ dokladu"
            name="signer-document-type"
            dusk="signer-document-type"
            :debounce="0"
            :options="documentTypes"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          field-path="signer.document.name"
          @validate="validateFnc"
        >
          <LitInput
            v-show="signerDocumentType === 'anotherDoc'"
            class="mb-20"
            label="Druh průkazu totožnosti"
            name="signer-document-name"
            dusk="signer-document-name"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :debounce="0"
          field-path="signer.document.number"
          @validate="validateFnc"
        >
          <LitInput
            class="mb-20"
            label="Číslo dokladu"
            name="signer-document-number"
            dusk="signer-document-number"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :debounce="0"
          field-path="signer.document.validUntil"
          @validate="validateFnc"
        >
          <DatePicker
            class="mb-20"
            label="Platný do"
            name="signer-document-valid"
            dusk="signer-document-valid"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @change="validate"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :debounce="0"
          field-path="signer.document.issuedBy"
          @validate="validateFnc"
        >
          <LitInput
            class="mb-30"
            label="Vydal"
            name="signer-document-issued"
            dusk="signer-document-issued"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>
      </div>
    </div>

    <h3 class="pt-30">
      Kontaktní e-mail pro zaslání dokumentace
    </h3>

    <p>
      Pokračováním potvrzuji, že&nbsp;uvedené údaje jsou pravdivé a&nbsp;účastník souhlasí s&nbsp;poskytnutím všech informací
      vztahujících se k&nbsp;dodatku v&nbsp;elektronické podobě. Účastníka jsem seznámil/a
      s&nbsp;<a href="https://www.csob.cz/csob/ochrana-osobnich-udaju" target="_blank" tabindex="-1">Informacemi o&nbsp;zpracování osobních údajů</a>
      a&nbsp;účastník souhlasí s&nbsp;kontaktováním na níže uvedené e-mailové adrese.
    </p>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate }"
      :namespace="props.namespace"
      field-path="clientData.email"
      :debounce="0"
      @validate="validateFnc"
    >
      <EmailInput
        name="email"
        dusk="email"
        label="E-mail"
        tooltip="V případě, že účastník nesouhlasí s poskytnutím e-mailu, využijte papírový formulář."
        :show-tooltip="true"
        :errors="errors"
        :model-value="value"
        @update:model-value="input"
        @blur="validate($event.target.value)"
      />
    </ServerValidatedFormField>
  </div>
</template>

<style lang="scss" scoped>
.debug-buttons {
  display: flex;
  flex-direction: column;
}
</style>
