<template>
  <div dusk="distributor-data">
    <h3>Údaje obchodního zástupce</h3>

    <div>
      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        field-path="beforeStart.s1"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          name="s1"
          dusk="s1"
          class="mb-20"
          placeholder="1234"
          label="S1 (Sjednatelské číslo distribuce)"
          :errors="errors"
          :model-value="value"
          @blur="input($event.target.value)"
        >
          <template #link>
            <router-link
              v-if="errors.length !== 0"
              :to="{ name: 'distribution.home' }"
              class="error"
              @click="removePersistedData('distribution')"
            >
              Změnit distribuci
            </router-link>
          </template>
        </LitInput>
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        field-path="beforeStart.s2"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          name="s2"
          dusk="s2"
          class="mb-20"
          placeholder="1234"
          label="S2 (Sjednatelské číslo prodejce)"
          :errors="errors"
          :model-value="value"
          @blur="input($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        field-path="beforeStart.s3"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          name="s3"
          dusk="s3"
          class="mb-20"
          placeholder="1234"
          label="S3 (Sjednatelské číslo tipaře)"
          :errors="errors"
          :model-value="value"
          @blur="input($event.target.value)"
        />
      </ServerValidatedFormField>

      <div v-if="displayAddressField">
        <h3 v-if="showMeetingAddressTitle">
          Adresa pošty
        </h3>
        <AddressEntry
          store-path="beforeStart.meetingAddress"
          namespace="changeRequest"
          name="meeting-address"
          dusk="meeting-address"
          label="Adresa pošty"
          :country-selection-allowed="false"
          :validate-fnc="validateField"
          @place-detail-loaded="updateMeetingAddress"
          @set-address-manually="`${namespace}.beforeStart.meetingAddress.setManually.value = true`"
        />
      </div>
    </div>

    <div>
      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        field-path="beforeStart.distributor.firstName"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Jméno obchodního zástupce"
          name="csobFirstName"
          dusk="csob-first-name"
          placeholder="Např. Karel"
          :model-value="value"
          :errors="errors"
          @blur="input($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, validate, input, errors }"
        :namespace="namespace"
        field-path="beforeStart.distributor.lastName"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Příjmení obchodního zástupce"
          name="csobLastName"
          dusk="csob-last-name"
          placeholder="Např. Novotný"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        field-path="beforeStart.distributor.email"
        :debounce="0"
        @validate="validateFnc"
      >
        <EmailInput
          name="csobEmail"
          dusk="csob-email"
          label="E-mail obchodního zástupce"
          :errors="errors"
          :model-value="value"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

import LitInput from '@/js/components/Base/LitInput.vue';
import EmailInput from '@/js/components/EmailInput.vue';

import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import AddressEntry from '@/js/components/AddressEntry.vue';
import type { Address } from '@/js/stores/types.ts';
import { useGeneralStore } from '@/js/stores';
import type { ValidateStoreParamsFnc } from '@/js/stores/utils.ts';
import { removePersistedData } from '@/js/utils.ts';

interface Props {
  namespace?: string
  fieldPath?: string
  displayAddressField?: boolean
  showMeetingAddressTitle: boolean
  validateFnc: ValidateStoreParamsFnc
}

withDefaults(defineProps<Props>(), {
  namespace: 'changeRequest',
});

const generalStore = useGeneralStore();
const store = useChangeRequestStore();
const { validateField } = store;

const {
  beforeStart,
} = storeToRefs(store);

const route = useRoute();

function updateAddress (
  prefix: 'beforeStart',
  name: 'meetingAddress',
  {
    city,
    street,
    streetNumber,
    zip,
    countryCode,
  }: Address,
) {
  store.$patch((state) => {
    state[prefix][name].street.value = street;
    state[prefix][name].streetNumber.value = streetNumber;
    state[prefix][name].city.value = city;
    state[prefix][name].zip.value = zip;
    // @ts-expect-error: personalData | legalRepresentative
    state[prefix][name].country.value = countryCode?.toLowerCase();
  });
}

function updateMeetingAddress (addressDetail: Address) {
  updateAddress('beforeStart', 'meetingAddress', addressDetail);
}

function getAffiliatedNumbers () {
  if (route.query.s1) {
    beforeStart.value.s1.value = route.query.s1 as string;
  }

  if (route.query.s2) {
    beforeStart.value.s2.value = route.query.s2 as string;
  }
}

function setTiedAgentItems () {
  beforeStart.value.isTiedAgent.value = generalStore.isTiedAgent.value;
  beforeStart.value.distributionName.value = generalStore.distribution.value;
}

onMounted(() => {
  getAffiliatedNumbers();
  setTiedAgentItems();
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

.error {
  color: getColor(danger, 1);
}
</style>
