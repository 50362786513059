import { useStoreField } from '@/js/composables/useStoreField';
import {
  addressField,
  legalRequirements,
  personalDocument,
} from '@/js/stores/createStoreFields';
import type { Nominee, NullableString, SubmitResponse } from '@/js/stores/types';

export const state = {
  rehydrated: useStoreField(false, false),

  beforeStart: {
    s1: useStoreField<string>(''),
    s2: useStoreField<string>(''),
    s3: useStoreField<NullableString>(''),
    meetingAddress: addressField(),
    isTiedAgent: useStoreField<boolean | null>(null),
    distributionName: useStoreField<NullableString>(''),
    distributor: {
      firstName: useStoreField<string>(''),
      lastName: useStoreField<string>(''),
      email: useStoreField<string>(''),
    },
  },

  clientData: {
    firstName: useStoreField<string>(''),
    lastName: useStoreField<string>(''),
    personalIdNumber: useStoreField(''),
    contractNumber: useStoreField<string>(''),
    email: useStoreField<string>(''),
    permanentAddressArea: useStoreField(''),
    birthDate: useStoreField<NullableString>(null),
  },

  signer: {
    type: useStoreField('participant'),
    firstName: useStoreField<NullableString>(null),
    lastName: useStoreField<NullableString>(null),
    personalIdNumber: useStoreField<NullableString>(null),
    permanentAddressArea: useStoreField<NullableString>(null),
    birthDate: useStoreField<NullableString>(null),

    document: personalDocument({}),
  },

  contractSettings: {
    contributionChangeRequired: useStoreField(false),
    contribution: {
      amount: useStoreField<number | null>(null),
      group: useStoreField(''),
      effectiveDate: useStoreField(null),
      grantedPension: useStoreField(false),
    },
    taxOptimizationChangeRequired: useStoreField<boolean>(false),
    taxOptimization: {
      value: useStoreField<boolean | null>(null),
      effectiveDate: useStoreField(null),
    },
    employerChangeRequired: useStoreField<boolean>(false),
    employer: {
      action: useStoreField<NullableString>(null),
      name: useStoreField<NullableString>(null),
      vat: useStoreField<NullableString>(null),
      effectiveDate: useStoreField(null),
    },
  },

  legalRequirements: {
    changeRequired: useStoreField<boolean>(false),
    ...legalRequirements(),
    effectiveDate: useStoreField(null),
  },

  nominees: {
    nomineesData: [] as Nominee[],

    changeRequired: useStoreField<boolean>(false),
    effectiveDate: useStoreField(null),
    nomineesShare: useStoreField(50),
  },

  documents: {
    changeRequired: useStoreField<boolean>(false),
    effectiveDate: useStoreField(null),

    idCard: {
      number: useStoreField<NullableString>('', true, undefined, true),
      validUntil: useStoreField<NullableString>('', true, undefined, true),
      issuedBy: useStoreField<NullableString>('', true, undefined, true),
    },

    secondDocument: personalDocument({}),
  },

  consents: {
    changeRequired: useStoreField<boolean>(false),
    effectiveDate: useStoreField(null),
    email: useStoreField(''),
    marketingPurposes: useStoreField(true),
  },

  personalData: {
    changeRequired: useStoreField<boolean>(false),
    effectiveDate: useStoreField(null),
    firstName: useStoreField(''),
    lastName: useStoreField(''),
    phoneNumber: useStoreField(''),
    phonePrefix: useStoreField('420'),
    permanentAddress: addressField(),
    mailingAddress: addressField(),
  },

  strategy: {
    changeRequired: useStoreField<boolean>(false),
    effectiveDate: useStoreField(null),
    selectCustomStrategy: useStoreField(false),
    showQuestionnaire: useStoreField(true),
    totalPoints: useStoreField<number | null>(null, false),

    questionnaire: {
      question1: useStoreField<NullableString>(null),
      question2: useStoreField<NullableString>(null),
      question3: useStoreField<NullableString>(null),
      question4: useStoreField<NullableString>(null),
      question5: useStoreField<NullableString>(null),
      question6: useStoreField<NullableString>(null),
    },

    selectedStrategy: useStoreField<NullableString>(null),

    fundCombination: useStoreField({
      dynamic: 0,
      balanced: 0,
      pension: 0,
      conservative: 0,
      esg: 0,
    }),
  },

  changeSettings: {
    signatureDate: useStoreField(''),
    signaturePlace: useStoreField(''),
    clientStatement: useStoreField(''),
    involvedForms: useStoreField<string[]>([]),
  },

  meetingRecordFilePath: useStoreField<NullableString>(null),

  draftToken: useStoreField<{ value: NullableString, expiresAt: NullableString }>({
    value: null,
    expiresAt: null,
  }, false),

  // These value is received at the very and after contract is submitted
  submitResponse: useStoreField<SubmitResponse | null>(null, false),
};
