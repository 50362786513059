<template>
  <div class="container--sm my-30">
    <div v-if="contractNumber === null">
      <LitAlert
        v-if="errors.length > 0"
        class="mb-20"
        alert-type="danger"
        dusk="contract-sending-failure"
      >
        <div>
          {{ errors[0].message }}
        </div>
      </LitAlert>

      <h2>Než začneme...</h2>
      <div>
        <LitInput
          v-model="s1.value"
          name="s1"
          dusk="s1"
          class="mb-20"
          placeholder="1234"
          label="S1 (Sjednatelské číslo distribuce)"
          :errors="s1.errors"
        />

        <LitInput
          v-model="s2.value"
          name="s2"
          dusk="s2"
          class="mb-20"
          placeholder="1234"
          label="S2 (Sjednatelské číslo prodejce)"
          :errors="s2.errors"
        />

        <div class="line" />

        <div
          class="text-center m-center"
        >
          <LitCaptcha
            :captcha-site-key="env.REST_API_CAPTCHA_KEY"
            :use-captcha="useCaptcha"
            @approve="captchaIsValid"
            @failed="isAuthenticatedByCaptcha = false"
          >
            <template
              v-if="isAuthenticatedByCaptcha"
              #button
            >
              <LitButton
                v-if="!isLoading && attemptsToGenerateNumber > 0"
                class="mt-30"
                @click="submitStep"
              >
                <span>
                  Vygenerovat číslo smlouvy
                </span>

                <template #after>
                  <ArrowRight :size="20" />
                </template>
              </LitButton>
            </template>
          </LitCaptcha>

          <h3 v-if="attemptsToGenerateNumber <= 0">
            maximální denní počet vygenerovaných čísel smluv překročen
          </h3>

          <div
            v-if="isLoading"
            class="loading-icon"
          >
            <LitIconSvg
              class="btn__icon spin flip"
              icon-name="icon-loader_green"
            />
          </div>
        </div>
      </div>
    </div>

    <ContractNumberGenerator
      v-else
      :contract-number="contractNumber"
      :attempts-to-generate-number="attemptsToGenerateNumber"
      :s1="s1.value"
      :s2="s2.value"
      @changed="changed"
    />
  </div>
</template>

<script>
import { parseInt } from 'lodash/string';
import axios from 'axios';
import { ArrowRight } from 'lucide-vue-next';
import LitInput from '../../components/Base/LitInput.vue';
import LitIconSvg from '../../components/Base/LitIconSvg.vue';
import ContractNumberGenerator from '../../components/ContractNumberGenerator.vue';
import LitAlert from '../../components/Base/LitAlert.vue';
import env from '../../env';
import LitCaptcha from '../../components/LitCaptcha.vue';
import LitButton from '@/js/components/Base/LitButton.vue';

export default {
  components: {
    LitCaptcha,
    LitAlert,
    ContractNumberGenerator,
    LitIconSvg,
    LitInput,
    LitButton,
    ArrowRight,
  },

  beforeRouteLeave (to, from, next) {
    return this.isAuthenticatedByCaptcha ? next() : this.$router.push({ name: 'contractNumberGenerator.distributorInformation' });
  },

  data: () => ({
    isLoading: false,
    captcha: null,
    isAuthenticatedByCaptcha: false,
    s1: { value: null, errors: [] },
    s2: { value: null, errors: [] },
    contractNumber: null,
    errors: [],
    attemptsToGenerateNumber: 5,
    restApiUri: env.REST_API_URI,
    useCaptcha: false,
  }),

  computed: {
    env () {
      return env;
    },
    requestData () {
      return {
        s1: this.s1.value,
        s2: this.s2.value,
        captcha: this.captcha,
        validateOnly: ['s1', 's2'],
      };
    },
  },

  mounted () {
    this.canGenerate();
  },

  methods: {
    captchaIsValid (captcha) {
      this.isAuthenticatedByCaptcha = true;
      this.captcha = captcha.responseToken;
      this.useCaptcha = false;
    },

    async submitStep () {
      try {
        this.clearErrors();
        this.isLoading = true;
        this.useCaptcha = true;

        const {
          data,
          headers,
        } = await axios.post(`${this.restApiUri}/v1/generated-numbers`, this.requestData);

        this.contractNumber = data.data.generated_number;
        this.attemptsToGenerateNumber = parseInt(headers['x-remaining-generation']);
        this.persistDataToLocalStorage(this.attemptsToGenerateNumber);
      } catch (e) {
        if (e.response.status === 422) {
          const { errors } = e.response.data;

          Object.entries(errors).forEach(([key, value]) => {
            this.$data[key].errors.push(...value);
          });
        } else if (e.response.status === 401) {
          const message = e.response.data.message;
          this.useCaptcha = true;

          this.errors.push({ message });
        } else {
          const message = e.response?.data || 'Něco se nepovedlo';

          if (e.response.headers['x-remaining-generation'] !== undefined) {
            this.attemptsToGenerateNumber = parseInt(e.response.headers['x-remaining-generation']);
            this.persistDataToLocalStorage(this.attemptsToGenerateNumber);
          }

          this.errors.push({ message });
        }
      } finally {
        this.isLoading = false;
        this.useCaptcha = false;
      }
    },

    changed (e) {
      this.contractNumber = e.contractNumber;
      this.attemptsToGenerateNumber = e.remainingLimit;
      this.persistDataToLocalStorage(e.remainingLimit);
    },

    canGenerate () {
      const currentDay = new Date().getDay();
      const generatedNumbers = localStorage.getItem('remaining_limits');

      if (generatedNumbers === null) {
        this.attemptsToGenerateNumber = 3;
      } else {
        const generatedNumbersData = JSON.parse(generatedNumbers);

        if (generatedNumbersData.remainingLimit <= 0
          && generatedNumbersData.dayOfMonth === currentDay) {
          this.attemptsToGenerateNumber = generatedNumbersData.remainingLimit;
        }
      }
    },

    persistDataToLocalStorage (remainingDays) {
      const dayOfMonth = new Date().getDay();
      const remainingLimit = parseInt(remainingDays);

      localStorage.setItem('remaining_limits', JSON.stringify({ dayOfMonth, remainingLimit }));
    },

    clearErrors () {
      this.s1.errors = [];
      this.s2.errors = [];
    },
  },
};
</script>

<style scoped lang="scss">
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.line {
  border-bottom: 1px solid getColor(light-grey);
  text-align: center;
  width: 100%;
}

.error-handler {
  width: 100%;
  background: getColor(--color-danger);
}

.loading-icon {
  svg {
    height: 50px;
    width: 50px;
  }
}
</style>
