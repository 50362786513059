export default [
  {
    bankCode: 'Vyberte',
    label: '',
  },
  {
    bankCode: '3030',
    label: 'Air bank',
  },
  {
    bankCode: '2250',
    label: 'Banka CREDITAS',
  },
  {
    bankCode: '0800',
    label: 'Česká spořitelna',
  },
  {
    bankCode: '0300',
    label: 'Československá obchodní banka',
  },
  {
    bankCode: '2010',
    label: 'Fio banka',
  },
  {
    bankCode: '0100',
    label: 'Komerční banka',
  },
  {
    bankCode: '6210',
    label: 'mBank',
  },
  {
    bankCode: '0600',
    label: 'MONETA Money Bank',
  },
  {
    bankCode: '5500',
    label: 'Raiffeisenbank',
  },
  {
    bankCode: '2700',
    label: 'UniCredit Bank',
  },
  {
    bankCode: '7940',
    label: 'Waldviertler Sparkasse Bank',
  },
  {
    bankCode: '2220',
    label: 'Artesa, spořitelní družstvo',
  },
  {
    bankCode: '8255',
    label: 'Bank of Communications Co',
  },
  {
    bankCode: '8250',
    label: 'Bank of China (CEE) Ltd. Prague Branch',
  },
  {
    bankCode: '3050',
    label: 'BNP Paribas Personal Finance SA',
  },
  {
    bankCode: '6300',
    label: 'BNP Paribas S.A., pobočka Česká republika',
  },
  {
    bankCode: '2060',
    label: 'Citfin, spořitelní družstvo',
  },
  {
    bankCode: '2600',
    label: 'Citibank Europe plc',
  },
  {
    bankCode: '6200',
    label: 'COMMERZBANK Aktiengesellschaft, pobočka Praha',
  },
  {
    bankCode: '8090',
    label: 'Česká exportní banka',
  },
  {
    bankCode: '0710',
    label: 'Česká národní banka',
  },
  {
    bankCode: '4300',
    label: 'Českomoravská záruční a rozvojová banka',
  },
  {
    bankCode: '7960',
    label: 'ČSOB Stavební spořitelna',
  },
  {
    bankCode: '7910',
    label: 'Deutsche Bank Aktiengesellschaft Filiale Prag, org. složka',
  },
  {
    bankCode: '4000',
    label: 'Expobank CZ',
  },
  {
    bankCode: '8150',
    label: 'HSBC Continental Europe, Czech Republic',
  },
  {
    bankCode: '2100',
    label: 'Hypoteční banka',
  },
  {
    bankCode: '8265',
    label: 'Industrial and Commercial Bank of China Lim. Prague Branch',
  },
  {
    bankCode: '3500',
    label: 'ING Bank N.V.',
  },
  {
    bankCode: '5800',
    label: 'J & T Banka',
  },
  {
    bankCode: '7990',
    label: 'Modrá pyramida stavební spořitelna',
  },
  {
    bankCode: '7970',
    label: 'MONETA Stavební Spořitelna',
  },
  {
    bankCode: '2020',
    label: 'MUFG Bank (Europe) N.V. Prague Branch',
  },
  {
    bankCode: '2260',
    label: 'NEY spořitelní družstvo',
  },
  {
    bankCode: '8040',
    label: 'Oberbank AG pobočka Česká republika',
  },
  {
    bankCode: '6363',
    label: 'Partners banka',
  },
  {
    bankCode: '2200',
    label: 'Peněžní dům, spořitelní družstvo',
  },
  {
    bankCode: '3060',
    label: 'PKO BP S.A., Czech Branch',
  },
  {
    bankCode: '2240',
    label: 'Poštová banka, a.s., pobočka Česká republika',
  },
  {
    bankCode: '6000',
    label: 'PPF banka',
  },
  {
    bankCode: '7950',
    label: 'Raiffeisen stavební spořitelna',
  },
  {
    bankCode: '8190',
    label: 'Sparkasse Oberlausitz-Niederschlesien',
  },
  {
    bankCode: '8060',
    label: 'Stavební spořitelna České spořitelny',
  },
  {
    bankCode: '2070',
    label: 'TRINITY BANK',
  },
  {
    bankCode: '8030',
    label: 'Volksbank Raiffeisenbank Nordoberpfalz eG pobočka Cheb',
  },
  {
    bankCode: '6700',
    label: 'Všeobecná úverová banka a.s., pobočka Praha',
  },
  {
    bankCode: '7980',
    label: 'Wüstenrot hypoteční banka',
  },
];
