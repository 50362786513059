<template>
  <div dusk="contract-signature">
    <div class="container--sm">
      <h2>Podpis smlouvy</h2>

      <LitAlert class="mb-20">
        <div class="smskey">
          <div class="smskey__text">
            Nyní podepište smlouvu pomocí SMS klíče, který jsme právě odeslali
            na váš telefon
            <span class="text-bold">
              +420 {{ signaturePhoneNumber && useFormatPhoneNumber(signaturePhoneNumber) }}
            </span>.
          </div>

          <button
            dusk="open-modal"
            class="button-action"
            @click="openModal"
          >
            <i>
              <img
                :src="getIconUrl('icon-edit')"
                alt="Ikona upravit"
              >
            </i>

            Změnit telefonní číslo
          </button>
        </div>
      </LitAlert>

      <LitAlert
        v-if="internalError"
        class="mb-20"
        alert-type="danger"
        dusk="contract-signing-error"
      >
        <div>
          Při odesílání smlouvy došlo k neočekávané chybě. Zkuste to prosím znovu
          a v případě, že se chyba bude opakovat nás prosím kontaktujte.
        </div>
      </LitAlert>

      <LitAlert
        v-if="validationError"
        class="mb-20"
        alert-type="danger"
        dusk="contract-signing-error"
      >
        <div>
          Při odesílání smlouvy došlo k chybě. Překontrolujte si prosím všechny zadané údaje
          a zkuste to znovu. V případě, že se chyba bude opakovat nás prosím kontaktujte.
        </div>
      </LitAlert>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        namespace="onlineAgreement"
        field-path="signature.smsKey"
        :debounce="0"
        @validate="validateField"
      >
        <LitInput
          class="mb-20"
          name="one-time-code"
          tooltip="V případě, že vám nedorazila sms, zkontrolujte si správnost telefonního čísla."
          label="SMS klíč k podpisu"
          placeholder="Vložte SMS klíč"
          autocomplete="one-time-code"
          input-type="numeric"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        >
          <template #actions>
            <div class="actions-group">
              <button
                v-if="attemptsRemaining !== null && attemptsRemaining > 0"
                type="button"
                @click="fetchSigningKey(signaturePhoneNumber)"
              >
                SMS klíč nedorazil, odeslat nový
              </button>
            </div>
          </template>
        </LitInput>
      </ServerValidatedFormField>

      <div v-if="attemptsRemaining === 0">
        Maximální počet odeslaných SMS byl vyčerpán.
      </div>

      <div v-if="signingKey">
        [DEBUG] SMS kód:
        <button
          type="button"
          dusk="sms-key"
          @click="smsKeySignature = signingKey"
        >
          <b>{{ signingKey }}</b>
        </button>
      </div>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <div class="container--sm flex flex-between">
      <router-link
        :to="{ name: 'onlineAgreement.contractDocumentsAndConsents' }"
        class="btn btn-big btn-icon-back btn-outline"
      >
        <LitIconSvg
          class="btn__icon btn__icon--big"
          icon-name="arrow_green-left"
        />

        <span>
          Zpět
        </span>
      </router-link>

      <button
        class="btn btn-green btn-big btn-icon-right"
        dusk="submit-step"
        :class="{ disabled: !isKeyProvided || submitting }"
        :disabled="!isKeyProvided || submitting"
        @click="submitStep"
      >
        <span>
          PODEPSAT SMLOUVU
        </span>

        <LitIconSvg
          class="btn__icon btn__icon--end btn__icon--end--big"
          icon-name="arrow_white-right"
        />
      </button>
    </div>

    <LitModal
      v-model="showModal"
    >
      <template #header>
        <h3>
          Změna telefonního čísla
        </h3>
      </template>

      <template #body>
        <LitAlert class="mb-20">
          <div class="mb-5">
            Zadejte nové telefonní číslo, na kterém jste k zastižení.
          </div>
          <div>
            Na toto telefonní číslo vám zároveň obratem zašleme SMS Klíč určený k podpisu smlouvy.
          </div>
        </LitAlert>

        <PhoneNumberInput
          v-model="modalPhoneNumber"
          dusk="modal-phone-number-input"
          class="control__number--modal"
          tooltip-position="left"
          :show-tooltip="false"
          :error="errorMessages[0]"
        />
      </template>

      <template #footer>
        <div class="modal__buttons">
          <button
            type="button"
            dusk="close-modal"
            class="btn btn-primary btn-outline"
            @click="showModal = false"
          >
            Zrušit
          </button>

          <button
            class="btn btn-green btn-primary"
            @click="saveNewPhoneNumber"
          >
            Uložit nový telefon
          </button>
        </div>
      </template>
    </LitModal>

    <ContractSubmissionModal
      type="online"
      :model-value="submitting"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

import { signatures, signingKeys } from '@/js/api';
import gtm from '@/js/services/gtm';

import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitModal from '@/js/components/Base/LitModal.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import PhoneNumberInput from '@/js/components/PhoneNumberInput.vue';

import { useFormatPhoneNumber } from '@/js/composables/useFormatPhoneNumber';
import { getIconUrl } from '@/js/utils';
import { useOnlineAgreementStore } from '@/js/stores';
import { measureAdobeAnalytics, submitContract, validateStoreField } from '@/js/stores/utils';

const store = useOnlineAgreementStore();
const { contactInformation, signature } = storeToRefs(store);
const { validateField } = store;

const route = useRoute();
const router = useRouter();

// This one is received only in non-production environments
// with APP_DEBUG enabled.
const signingKey = ref(null);

const submitting = ref(false);
const showModal = ref(false);
const modalPhoneNumber = ref('');
const attemptsRemaining = ref<null | number>(null);
const internalError = ref(false);
const validationError = ref(false);
const errorMessages = ref<string[]>([]);

const phoneNumber = computed(() => {
  return contactInformation.value.phoneNumber.value;
});

const signaturePhoneNumber = computed({
  get () {
    return signature.value.phoneNumber.value;
  },
  set (value) {
    signature.value.phoneNumber.value = value;
  },
});

const smsKeySignature = computed({
  get () {
    return signature.value.smsKey.value;
  },
  set (value) {
    signature.value.smsKey.value = value;
  },
});

const isKeyProvided = computed(() => {
  return (smsKeySignature.value || '').toString().length > 0;
});

function resetErrors () {
  internalError.value = false;
  validationError.value = false;
}

function openModal () {
  showModal.value = true;
  modalPhoneNumber.value = signaturePhoneNumber.value || '';
}

function saveNewPhoneNumber () {
  const phoneNumberRegex = /^(\d{3})\s?(\d{3})\s?(\d{3})$/;
  const numberIsValid = modalPhoneNumber.value?.match(phoneNumberRegex);

  if (!numberIsValid) {
    errorMessages.value = ['Zadejte telefonní číslo ve formátu 777888999'];
  } else {
    signaturePhoneNumber.value = modalPhoneNumber.value;
    showModal.value = false;
    errorMessages.value = [];
  }
}

async function fetchSigningKey (phoneNumber: string | null) {
  const { contractUuid } = route.params;

  try {
    const { headers, data } = await signingKeys.create(
      contractUuid,
      phoneNumber?.replace(' ', ''),
    );

    if (data.code) {
      signingKey.value = data.code;
    }

    if ('x-ratelimit-remaining' in headers) {
      attemptsRemaining.value = Number.parseInt(headers['x-ratelimit-remaining']!, 10);
    }
  } catch (e: any) {
    if (e.response.status === 429) {
      console.error('Maximální počet SMS překročen');
      attemptsRemaining.value = 0;
    } else {
      throw e;
    }
  }
}

async function submitStep () {
  resetErrors();

  const { contractUuid } = route.params;

  try {
    submitting.value = true;

    await validateStoreField({
      state: store.$state,
      value: smsKeySignature.value,
      contractUuid,
      throwOnErrors: true,
      fieldPath: 'signature.smsKey',
    });

    const signature = [
      {
        signer: 'client',
        signMethod: 'sms',
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), true);

    await submitContract(store.$state, {
      contractUuid: contractUuid as string,
      throwOnErrors: true,
      signingKey: smsKeySignature.value,
      signature,
    });

    gtm.onStepSubmit('smlouva-sms-podpis');

    measureAdobeAnalytics({
      state: store.$state,
      action: 'ufSubmit',
      contractUuid,
      path: route.path,
      fields: [
        { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
        { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
        { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
        { storePath: 'contactInformation.email.value', fieldName: 'email' },
        { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
        { fetcher: () => true, fieldName: 'marketingagreement' },
      ],
    });

    await router.push({ name: 'onlineAgreement.thankYouPage' });
  } catch (e: any) {
    submitting.value = false;

    const signingKeyErrors = e?.response?.data?.errors?.signingKey;
    const statusCode = e?.response?.status;

    if (signingKeyErrors) {
      signature.value.smsKey.errors = signingKeyErrors;

      return;
    }

    if (statusCode === 422) {
      validationError.value = true;
    } else {
      internalError.value = true;
    }

    throw new Error(`Online contract ${contractUuid} (e2e) submission error. Status code ${statusCode}`);
  }
}

watch(signaturePhoneNumber, (number) => {
  if (number) {
    fetchSigningKey(number);
  }
});

watch(showModal, (val) => {
  if (val === false) {
    errorMessages.value = [];
  }
});

onMounted(() => {
  signaturePhoneNumber.value = phoneNumber.value;
});
</script>

<style lang="scss" scoped>
  @import '@sass/tools/variables';
  @import '@sass/tools/mixins';

  .button-action {
    flex-shrink: 0;
    margin-left: auto;
    margin-top: 10px;

    @include media(min, $md) {
      margin-left: 20px;
      margin-top: 0;
    }
  }

  .smskey {
    display: flex;

    @include media(max, $md) {
      flex-flow: column;
    }

    &__text {
      flex: 1;
    }
  }
</style>
