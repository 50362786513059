<template>
  <div>
    <slot name="form" />

    <VueRecaptcha
      ref="recaptcha"
      :sitekey="captchaSiteKey"
      :size="captchaVisibility"
      @verify="onRecaptchaVerify"
      @expired="onRecaptchaExpired"
    />

    <slot name="button" />
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import env from '../env';

export default {
  components: {
    VueRecaptcha,
  },

  props: {
    captchaSiteKey: {
      type: String,
      default: env.REST_API_CAPTCHA_KEY,
    },

    useCaptcha: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'approve',
    'failed',
  ],

  data: () => ({
    env,
    captchaVisibility: 'invisible',
    captchaResponse: null,
  }),

  watch: {
    useCaptcha (val) {
      if (val) {
        this.$refs.recaptcha?.execute();
        this.$refs.recaptcha?.reset();
        this.onRecaptchaVerify();
      }
    },
  },

  async mounted () {
    this.triggerCaptcha();
    document.addEventListener('click', this.onClickOutside);
  },

  methods: {
    onRecaptchaVerify (response) {
      if (response) {
        try {
          this.captchaResponse = response;
          this.$emit('approve', { responseToken: response });
        } catch (e) {
          console.warn(e);

          this.$emit('failed');
        }
      } else {
        this.$emit('failed');
      }
    },

    onClickOutside () {
      this.$refs.recaptcha?.execute();
    },

    triggerCaptcha () {
      this.$refs.recaptcha?.execute();
    },

    onRecaptchaExpired () {
      this.$refs.recaptcha?.reset();
    },
  },
};
</script>
