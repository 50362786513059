<template>
  <div dusk="personal-data">
    <OnlineAgreementStepper :current-step="1" />

    <div class="container--sm">
      <h2 v-if="!isChild">
        Nyní se podíváme na vaše osobní údaje
      </h2>
      <h2 v-else>
        Nyní se podíváme na osobní údaje vašeho dítěte
      </h2>

      <!-- Přidat podmínku, zobrazit pokud přišel uživatel
      přes nahrání či fotografování dokladů -->
      <LitAlert
        v-if="!isChild && !isPersonalDataFilledManually && !isBankIdEntry"
        alert-type="warning"
      >
        Doplňte prosím své osobní údaje, adresu a údaje z osobních dokladů.
      </LitAlert>

      <LitAlert v-if="isChild">
        Níže vyplňte osobní údaje dítěte, pro které sjednáváte penzijní spoření.
        V části Zákonný zástupce uveďte své údaje,
        případně údaje zástupce, který bude podepisovat smlouvu.
      </LitAlert>

      <!-- Přidat podmínku, zobrazit pokud NEpřišel uživatel
      přes nahrání či fotografování dokladů -->
      <LitAlert v-if="!isChild && isPersonalDataFilledManually">
        Vyplňte prosím své osobní údaje.
      </LitAlert>

      <form
        method="post"
        @submit.prevent="submitStep"
      >
        <div dusk="personal-information">
          <h3 v-if="!isChild">
            Vaše osobní údaje
          </h3>
          <h3 v-else>
            Osobní údaje dítěte
          </h3>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate, readOnly }"
            namespace="onlineAgreement"
            field-path="personalData.firstName"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="given-name"
              dusk="first-name"
              placeholder="Např. Karel"
              :label="labels.firstName"
              :warning="nameWarning"
              :disabled="readOnly"
              :read-only="readOnly"
              :model-value="value"
              :errors="errors"
              :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate, readOnly }"
            namespace="onlineAgreement"
            field-path="personalData.lastName"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="family-name"
              dusk="last-name"
              placeholder="Např. Novák"
              :label="labels.lastName"
              :disabled="readOnly"
              :read-only="readOnly"
              :model-value="value"
              :errors="errors"
              :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate, readOnly }"
            namespace="onlineAgreement"
            field-path="personalData.personalIdNumber"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="personalID"
              :disabled="readOnly"
              :read-only="readOnly"
              :label="labels.personalId"
              :model-value="value"
              :errors="errors"
              :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
              @update:model-value="input"
              @blur="validate($event.target.value)"
              @keydown.space.prevent
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate, readOnly }"
            namespace="onlineAgreement"
            field-path="personalData.citizenship"
            :validate-on-input="true"
            :debounce="0"
            @validate="validateField"
          >
            <LitSelect
              v-if="!readOnly"
              class="mb-20"
              :model-value="value"
              :searchable="true"
              :validate-on-input="true"
              :label="labels.citizenship"
              :options="citizenshipOptions"
              :error="errors.length > 0 ? errors[0] : ''"
              @update:model-value="input"
            />

            <LitInput
              v-else
              class="mb-20"
              name="citizenship"
              :disabled="readOnly"
              :read-only="readOnly"
              :label="labels.citizenship"
              :model-value="citizenshipOptions.find(country => country.value === value)?.label"
              :errors="errors"
              :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
              @update:model-value="input"
              @blur="validate($event.target.value)"
              @keydown.space.prevent
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate, readOnly }"
            namespace="onlineAgreement"
            field-path="personalData.countryOfBirth"
            :validate-on-input="true"
            :debounce="0"
            @validate="validateField"
          >
            <LitSelect
              v-if="!readOnly"
              class="mb-20"
              :model-value="value"
              :searchable="true"
              :label="labels.countryOfBirth"
              :options="countryOfBirthOptions"
              :error="errors.length > 0 ? errors[0] : ''"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />

            <LitInput
              v-else
              class="mb-20"
              name="countryOfBirth"
              :disabled="readOnly"
              :read-only="readOnly"
              :label="labels.countryOfBirth"
              :model-value="countryOfBirthOptions.find(country => country.value === value)?.label"
              :errors="errors"
              :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
              @update:model-value="input"
              @blur="validate($event.target.value)"
              @keydown.space.prevent
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate, readOnly }"
            namespace="onlineAgreement"
            field-path="personalData.birthplace"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="birthplace"
              :label="labels.birthplace"
              :disabled="readOnly"
              :read-only="readOnly"
              :model-value="value"
              :errors="errors"
              :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <AddressEntry
            store-path="personalData.permanentAddress"
            namespace="onlineAgreement"
            name="permanent-address"
            dusk="permanent-address"
            :tooltip="isChild === true ? 'Postupným psaním názvu ulice, čísla popisného '
              + 'a PSČ si můžete ze seznamu vybrat potřebnou adresu.' : ''"
            :show-alert="!isChild && !isPermanentAddressQueryReadOnly"
            :label="labels.permanentAddress"
            :disabled="isPermanentAddressQueryReadOnly"
            :show-action-buttons="!isPermanentAddressQueryReadOnly"
            :description="isPermanentAddressQueryReadOnly
              ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
            :validate-fnc="validateField"
            @place-detail-loaded="updatePermanentAddress"
            @set-address-manually="personalData.permanentAddress.setManually.value = true"
          />

          <AddressEntry
            v-show="personalData.specifyMailingAddress.value"
            label="Korespondenční adresa"
            name="mailing-address"
            dusk="mailing-address"
            namespace="onlineAgreement"
            store-path="personalData.mailingAddress"
            tooltip="Postupným psaním názvu ulice, čísla popisného
            a PSČ si můžete ze seznamu vybrat potřebnou adresu."
            :validate-fnc="validateField"
          >
            <template #autocomplete-actions>
              <button
                type="button"
                class="btn btn-simple btn-simple--red"
                @click="personalData.mailingAddress.setManually.value = false;
                        personalData.specifyMailingAddress.value = false"
              >
                <i>
                  <img
                    :src="getIconUrl('icon-delete')"
                    alt="Ikona smazat"
                  >
                </i>
                Odstranit adresu
              </button>
            </template>
          </AddressEntry>

          <button
            v-show="!personalData.specifyMailingAddress.value"
            type="button"
            class="btn-add mb-30"
            dusk="add-mailing-address"
            @click="personalData.specifyMailingAddress.value = true"
          >
            <i>
              <img
                :src="getIconUrl('btn-plus')"
                alt="Ikona plus"
              >
            </i>

            <span>
              Přidat korespondenční adresu
            </span>
          </button>
        </div>

        <template v-if="!isChild && !isPersonalDataFilledManually">
          <div dusk="document-data">
            <h3>Občanský průkaz</h3>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate, readOnly }"
              namespace="onlineAgreement"
              field-path="personalData.idCard.number"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                label="Číslo OP"
                name="idCardNumber"
                :disabled="readOnly"
                :read-only="readOnly"
                :warning="warning.idCard"
                :model-value="value"
                :errors="errors"
                :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
                @update:model-value="input"
                @blur="validate($event.target.value);checkIdCardFormat();"
              />
            </ServerValidatedFormField>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate, readOnly }"
              namespace="onlineAgreement"
              field-path="personalData.idCard.validUntil"
              dusk="id-card-validity"
              :debounce="0"
              @validate="validateField"
            >
              <DatePicker
                v-if="!readOnly"
                class="mb-20"
                label="Platný do"
                name="idValidUntil"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @change="validate"
              />
              <LitInput
                v-else
                class="mb-20"
                label="Platný do"
                name="idValidUntil"
                :disabled="readOnly"
                :read-only="readOnly"
                :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
              />
            </ServerValidatedFormField>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate, readOnly }"
              namespace="onlineAgreement"
              field-path="personalData.idCard.issuedBy"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-30"
                label="Vydal"
                name="IDIssued"
                :disabled="readOnly"
                :read-only="readOnly"
                :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              />
            </ServerValidatedFormField>

            <div v-if="!isBankIdEntry">
              <h3>Druhý doklad</h3>

              <ServerValidatedFormField
                v-slot="{ value, input, errors, validate }"
                namespace="onlineAgreement"
                field-path="personalData.secondDocument.number"
                :debounce="0"
                @validate="validateField"
              >
                <LitInput
                  class="mb-20"
                  label="Číslo dokladu"
                  name="secondDocumentNumber"
                  :warning="warning.secondDocument"
                  :model-value="value"
                  :errors="errors"
                  @update:model-value="input"
                  @blur="validate($event.target.value);checkSecondDocumentFormat();"
                />
              </ServerValidatedFormField>

              <ServerValidatedFormField
                v-slot="{ value, input, errors, validate }"
                namespace="onlineAgreement"
                field-path="personalData.secondDocument.validUntil"
                dusk="second-document-validity"
                :debounce="0"
                @validate="validateField"
              >
                <DatePicker
                  class="mb-20"
                  label="Platný do"
                  name="secondDocumentValidUntil"
                  :model-value="value"
                  :errors="errors"
                  @update:model-value="input"
                  @change="validate"
                />
              </ServerValidatedFormField>

              <ServerValidatedFormField
                v-slot="{ value, input, errors, validate }"
                namespace="onlineAgreement"
                field-path="personalData.secondDocument.issuedBy"
                :debounce="0"
                @validate="validateField"
              >
                <LitInput
                  class="mb-20"
                  label="Vydal"
                  name="secondDocumentIssuedBy"
                  :model-value="value"
                  :errors="errors"
                  @update:model-value="input"
                  @blur="validate($event.target.value)"
                />
              </ServerValidatedFormField>
            </div>
          </div>
        </template>

        <template v-if="isChild">
          <div dusk="signing-person">
            <h3>Zákonný zástupce</h3>

            <LitAlert class="mb-20">
              Smlouvu vám k podpisu doveze kurýr. Budeme potřebovat doložit <strong>kopii rodného listu
                vašeho dítěte</strong>, abychom si ověřili, že jste oprávněn/a jeho jménem uzavřít smlouvu
              o penzijním spoření. Ponecháte ji přiloženou <strong>u podepsané smlouvy</strong>.
            </LitAlert>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="onlineAgreement"
              field-path="legalRepresentative.firstName"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                label="Jméno"
                name="legalRepresentativeFirstname"
                :model-value="value"
                :errors="errors"
                :warning="nameWarning"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              />
            </ServerValidatedFormField>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="onlineAgreement"
              field-path="legalRepresentative.lastName"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                label="Příjmení"
                name="legalRepresentativeLastname"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              />
            </ServerValidatedFormField>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="onlineAgreement"
              field-path="legalRepresentative.personalIdNumber"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                name="legalRepresentativePersonalID"
                label="Rodné číslo"
                dusk="legal-representative-personal-number"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
                @keydown.space.prevent
              />
            </ServerValidatedFormField>

            <ServerValidatedFormField
              v-slot="{ value, input, errors }"
              namespace="onlineAgreement"
              field-path="legalRepresentative.citizenship"
              :debounce="0"
              @validate="validateField"
            >
              <LitSelect
                class="mb-20"
                label="Státní občanství"
                :model-value="value"
                :validate-on-input="true"
                :options="citizenshipOptions"
                :error="errors.length > 0 ? errors[0] : ''"
                @update:model-value="input"
              />
            </ServerValidatedFormField>

            <ServerValidatedFormField
              v-slot="{ value, input, errors }"
              namespace="onlineAgreement"
              field-path="legalRepresentative.countryOfBirth"
              :debounce="0"
            >
              <LitSelect
                class="mb-20"
                label="Stát narození"
                :model-value="value"
                :validate-on-input="true"
                :options="countryOfBirthOptions"
                :error="errors.length > 0 ? errors[0] : ''"
                @update:model-value="input"
              />
            </ServerValidatedFormField>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="onlineAgreement"
              field-path="legalRepresentative.birthplace"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                name="legalRepresentativeBirthplace"
                label="Místo narození"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              />
            </ServerValidatedFormField>

            <AddressEntry
              v-model="legalRepresentativePermanentAddress"
              label="Trvalá adresa"
              name="signer-permanent-address"
              dusk="signer-permanent-address"
              namespace="onlineAgreement"
              store-path="legalRepresentative.permanentAddress"
              :show-alert="true"
              :validate-fnc="validateField"
              @place-detail-loaded="updateLegalRepresentativePermanentAddress"
              @set-address-manually="legalRepresentative.permanentAddress.setManually.value = true"
            >
              <template #autocomplete-actions>
                <button
                  type="button"
                  class="btn-simple"
                  @click="setLegalRepresentativePermanentAddressSameAsChild"
                >
                  Shodná s trvalou adresou klienta
                </button>
              </template>
            </AddressEntry>
          </div>
        </template>
      </form>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <div class="container--sm flex flex-between">
      <button
        type="button"
        class="btn btn-big btn-icon-back btn-outline"
        @click="returnButtonClick"
      >
        <LitIconSvg
          class="btn__icon btn__icon--big"
          icon-name="arrow_green-left"
        />

        <span>
          Zpět
        </span>
      </button>

      <button
        type="button"
        class="btn btn-green btn-big btn-icon-right"
        dusk="submit-step"
        :class="{ disabled: validating }"
        :disabled="validating"
        @click="submitStep"
      >
        <span>
          Pokračovat
        </span>

        <LitIconSvg
          class="btn__icon btn__icon--end btn__icon--end--big"
          icon-name="arrow_white-right"
        />
      </button>
    </div>

    <LitModal
      v-model="showModalForForeigners"
      :max-width="938"
    >
      <template #header>
        <h3>
          Je nám líto, ale vaši žádost bohužel nemůžeme dokončit online
        </h3>
      </template>

      <template #body>
        <LitAlert alert-type="danger">
          Online založení penzijního spoření je možné
          <span class="text-bold">pouze pro občany České republiky
            s trvalým pobytem na území České republiky</span>.<br>
          Dle Vámi zadaných údajů tuto podmínku bohužel nesplňujete.
        </LitAlert>

        <h3>Jste občan ČR s trvalým pobytem v ČR?</h3>
        <p>
          Pokud jste občanem ČR s trvalým pobytem v ČR,
          opravte, prosím, zadané údaje a pokračujte v online sjednání penzijního spoření.
        </p>
        <h3>Jste cizinec a/nebo máte trvalý pobyt mimo ČR?</h3>
        <p>
          V takovém případě se s Vámi potřebujeme setkat osobně na naší pobočce,
          kde vám penzijní spoření sjednáme.
        </p>
        <p>
          Vyberte si <a
            target="_blank"
            href="https://www.csob-penze.cz/pobocky/"
          >
            jakoukoliv naši pobočku</a>
          nebo nás kontaktujte na telefonu
          <a href="tel:+420495800600">
            495 800 600
          </a>
          a my vám se založením penzijního spoření poradíme.
        </p>
      </template>

      <template #footer>
        <div class="modal__buttons">
          <button
            dusk="close-modal"
            class="btn btn-primary btn-outline mr-12"
            @click="setCzechRepublic"
          >
            JSEM OBČAN ČR ŽIJÍCÍ V ČR A CHCI DOKONČIT ŽÁDOST ONLINE
          </button>

          <a
            class="btn btn-green btn-primary"
            target="_blank"
            href="https://www.csob-penze.cz/pobocky/"
          >
            JSEM CIZINEC, CHCI NAJÍT POBOČKU A SJEDNAT SCHŮZKU
          </a>
        </div>
      </template>
    </LitModal>

    <LitModal
      v-model="showModalForNextStep"
    >
      <template #header>
        <h3>
          Zkontrolovali jste si své osobní údaje?
        </h3>
      </template>

      <template #body>
        <h4>Překontrolujte prosím následující pole:</h4>
        <ul class="list personal-data__modal">
          <li> Státní občanství: <span>{{ citizenshipFullName }}</span></li>
          <li> Stát narození: <span>{{ countryOfBirthFullName }}</span></li>
          <li> Místo narození: <span>{{ personalData.birthplace.value }}</span></li>
          <li> Číslo občanského průkazu: <span>{{ personalData.idCard.number.value }}</span></li>
          <li> a jeho platnost: <span>{{ personalData.idCard.validUntil.value }}</span></li>
        </ul>
      </template>

      <template #footer>
        <div class="modal__buttons">
          <button
            dusk="close-modal"
            class="btn btn-primary btn-outline"
            @click="showModalForNextStep = false"
          >
            Zpět
          </button>

          <button
            class="btn btn-green btn-primary"
            @click="submitStep"
          >
            Pokračovat
          </button>
        </div>
      </template>
    </LitModal>

    <LitModal
      v-model="showStepBackWarning"
      :max-width="938"
    >
      <template #header>
        <h3>
          Opravdu se chcete vrátit zpět?
        </h3>
      </template>

      <template #body>
        <p class="py-20">
          Návratem na předchozí obrazovku dojde ke smazání všech údajů předaných z BankId.
        </p>
      </template>

      <template #footer>
        <div class="modal__buttons">
          <button
            type="button"
            class="btn btn-big btn-icon-back btn-outline"
            @click="goBack"
          >
            <LitIconSvg
              class="btn__icon btn__icon--big"
              icon-name="arrow_green-left"
            />

            <span>
              ROZUMÍM, PŘESTO SE VRÁTÍM ZPĚT
            </span>
          </button>
          <button
            type="button"
            dusk="close-modal"
            class="btn btn-big btn-outline"
            @click="showStepBackWarning = false"
          >
            ZŮSTANU NA TÉTO STRÁNCE
          </button>
        </div>
      </template>
    </LitModal>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';
import { get, set } from 'lodash';
import type { StateTree } from 'pinia';
import { storeToRefs } from 'pinia';

import { useRoute, useRouter } from 'vue-router';
import { useOnlineAgreementStore } from '@/js/stores/online/agreement';

import { clientsPersonalDataFields } from '@/js/data/validationFields/commonFields';
import { documentInfoFields, legalRepresentativeFields } from '@/js/data/validationFields/online';

import gtm from '@/js/services/gtm';

import LitInput from '@/js/components/Base/LitInput.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitModal from '@/js/components/Base/LitModal.vue';
import DatePicker from '@/js/components/DatePicker.vue';
import OnlineAgreementStepper from '@/js/components/Steppers/OnlineAgreementStepper.vue';
import AddressEntry from '@/js/components/AddressEntry.vue';

import { countriesOfBirth as countryOfBirthOptions } from '@/js/data/countryOfBirthOptions';
import { citizenshipCountries as citizenshipOptions } from '@/js/data/citizenshipOptions';

import { canEnterRent, getIconUrl, scrollToError, traverseObject } from '@/js/utils';
import { copyFields, measureAdobeAnalytics } from '@/js/stores/utils';
import type { Address } from '@/js/stores/types';
import { useCheckTitleInName } from '@/js/composables/useTitleInName.ts';

const store = useOnlineAgreementStore();
const {
  personalData,
  legalRepresentative,
  isBankIdEntry,
  isChild,
  participantsBirthDay,
  isPersonalDataFilledManually,
} = storeToRefs(store);
const { validateField, validateFields, resetPensionQuestionnaire } = store;

const route = useRoute();
const router = useRouter();

const validating = ref(false);
const permanentAddress = ref(null);
const mailingAddress = ref(null);
const showModalForForeigners = ref(false);
const showModalForNextStep = ref(false);
const legalRepresentativePermanentAddress = ref(null);
const warning = ref<{
  idCard: string | undefined
  secondDocument: string | undefined
}>({
  idCard: undefined,
  secondDocument: undefined,
});
const nextStepEnabled = ref(false);
const showStepBackWarning = ref(false);

const labels = computed(() => {
  if (isChild.value) {
    return {
      firstName: 'Jméno dítěte',
      lastName: 'Příjmení dítěte',
      personalId: 'Rodné číslo dítěte',
      citizenship: 'Státní občanství dítěte',
      countryOfBirth: 'Stát narození dítěte',
      birthplace: 'Místo narození dítěte',
      permanentAddress: 'Trvalá adresa dítěte',
    };
  }

  return {
    firstName: 'Jméno',
    lastName: 'Příjmení',
    personalId: 'Rodné číslo',
    citizenship: 'Státní občanství',
    countryOfBirth: 'Stát narození',
    birthplace: 'Místo narození',
    permanentAddress: 'Trvalá adresa',
  };
});

const isCitizenShipCz = computed(() => personalData.value.citizenship.value === 'cz');

const countryOfBirthFullName = computed(() => {
  return countryOfBirthOptions.filter(
    (country) => country.value === personalData.value.countryOfBirth.value,
  ).map((country) => country.label)[0];
});

const citizenshipFullName = computed(() => {
  return citizenshipOptions.filter(
    (country) => country.value === personalData.value.citizenship.value,
  ).map((country) => country.label)[0];
});

const preferConservativeFund = computed(() => {
  return canEnterRent(participantsBirthDay.value);
});

const firstName = computed<string>(() => personalData.value.firstName.value);
const nameWarning = useCheckTitleInName(firstName);

const hasSecondDocumentStandardFormat = computed(() => {
  // standard formats of documents numbers
  const drivingLicence = /^([aeiou])[a-z](\s)*\d{6}$/i;
  const passport = /^[1-9]\d{7}$/;

  const secondDocumentNumber = personalData.value.secondDocument.number.value;

  return (secondDocumentNumber && drivingLicence.test(secondDocumentNumber))
    || (secondDocumentNumber && passport.test(secondDocumentNumber));
});

const hasIdCardStandardFormat = computed(() => {
  const standardFormat = /^\d{9}$/;

  return personalData.value.idCard.number.value ? standardFormat.test(personalData.value.idCard.number.value) : false;
});

const isPermanentAddressQueryReadOnly = computed(() => {
  // @ts-expect-error: what is this?
  return personalData.value.permanentAddress.query?.readOnly; // TODO: ??
});

function loadBankIdFields (state: StateTree) {
  traverseObject(state, ({ nodeKey, parentPath }) => {
    if (nodeKey === '__storeField') {
      const bankId = get(state, `${parentPath}.bankIdReceivable`);
      const val = get(state, `${parentPath}.value`);

      if (route.query.bankId === 'success' && bankId === true && val !== null) {
        set(state, `${parentPath}.readOnly`, true);
      }
    }
  });
}

function copyContactNameToPersonalData () {
  copyFields(store.$state, [
    {
      pathFrom: 'contactInformation.firstName.value',
      pathTo: 'personalData.firstName.value',
    },
    {
      pathFrom: 'contactInformation.lastName.value',
      pathTo: 'personalData.lastName.value',
    },
  ]);
}

function showCitizenshipModal () {
  showModalForForeigners.value = !isCitizenShipCz.value;
}

function showLeavingModal () {
  const idCardNumber = personalData.value.idCard.number.value;
  if (idCardNumber === '' || idCardNumber === null) {
    nextStepEnabled.value = true;
  }
}

function checkSecondDocumentFormat () {
  if (hasSecondDocumentStandardFormat.value === false) {
    warning.value.secondDocument = 'Vyplnili jste číslo dokladu, které není '
    + 'v obvyklém formátu. Prosím zkontrolujte si číslo dokladu.';
  } else {
    warning.value.secondDocument = '';
  }
}

function checkIdCardFormat () {
  if (!hasIdCardStandardFormat.value) {
    warning.value.idCard = 'Vyplnili jste číslo občanského průkazu, které není '
    + 'v obvyklém formátu. Prosím zkontrolujte si číslo dokladu.';
  } else {
    warning.value.idCard = '';
  }
}

function setLegalRepresentativePermanentAddressSameAsChild () {
  store.$patch((state) => {
    state.legalRepresentative.permanentAddress.query.value = state.personalData.permanentAddress.query.value;
    state.legalRepresentative.permanentAddress.streetNumber.value = state.personalData.permanentAddress.streetNumber.value;
    state.legalRepresentative.permanentAddress.street.value = state.personalData.permanentAddress.street.value;
    state.legalRepresentative.permanentAddress.city.value = state.personalData.permanentAddress.city.value;
    state.legalRepresentative.permanentAddress.zip.value = state.personalData.permanentAddress.zip.value;
    state.legalRepresentative.permanentAddress.country.value = state.personalData.permanentAddress.country.value;
  });

  legalRepresentativePermanentAddress.value = permanentAddress.value;
}

function updateAddress<T extends 'personalData' | 'legalRepresentative'> (
  prefix: T,
  name: T extends 'personalData'
    ? 'permanentAddress' | 'mailingAddress'
    : 'permanentAddress',
  {
    city,
    street,
    streetNumber,
    zip,
    countryCode,
  }: Address,
) {
  store.$patch((state) => {
    // @ts-expect-error: personalData | legalRepresentative
    state[prefix][name].street.value = street;
    // @ts-expect-error: personalData | legalRepresentative
    state[prefix][name].streetNumber.value = streetNumber;
    // @ts-expect-error: personalData | legalRepresentative
    state[prefix][name].city.value = city;
    // @ts-expect-error: personalData | legalRepresentative
    state[prefix][name].zip.value = zip;
    // @ts-expect-error: personalData | legalRepresentative
    state[prefix][name].country.value = countryCode?.toLowerCase();
  });
}

function updatePermanentAddress (addressDetail: Address) {
  updateAddress('personalData', 'permanentAddress', addressDetail);
}

function updateLegalRepresentativePermanentAddress (addressDetail: Address) {
  updateAddress('legalRepresentative', 'permanentAddress', addressDetail);
}

function setCzechRepublic () {
  personalData.value.citizenship.value = 'cz';
  legalRepresentative.value.citizenship.value = 'cz';
  personalData.value.permanentAddress.country.value = 'cz';
  legalRepresentative.value.permanentAddress.country.value = 'cz';

  clearErrorMessages();
  showModalForForeigners.value = false;
}

function clearErrorMessages () {
  personalData.value.citizenship.errors = [];
  legalRepresentative.value.citizenship.errors = [];
  personalData.value.permanentAddress.city.errors = [];
  legalRepresentative.value.permanentAddress.country.errors = [];
}

function returnButtonClick () {
  if (isBankIdEntry.value) {
    showStepBackWarning.value = true;
  } else {
    goBack();
  }
}

function goBack () {
  router.push({
    name: isChild.value
      ? 'onlineAgreement.contactInformation'
      : 'onlineAgreement.personalDataEntryMethodSelection',
    query: { bankId: isBankIdEntry.value ? 'navrat' : null },
  });
}

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid: contractUuid as string,
      throwOnErrors: true,
      fieldPaths: [
        ...clientsPersonalDataFields,
        ...documentInfoFields,
        ...legalRepresentativeFields,
      ],
    });

    validating.value = false;

    if (isBankIdEntry.value) {
      showModalForNextStep.value = false;
      nextStepEnabled.value = true;
    }

    if (!nextStepEnabled.value) {
      showModalForNextStep.value = true;
      nextStepEnabled.value = true;

      return;
    }

    gtm.onStepSubmit('zadani-osobnich-udaju');

    measureAdobeAnalytics({
      state: store.$state,
      action: 'ufSubmit',
      contractUuid,
      path: route.path,
      fields: [
        { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
        { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
        { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
        { storePath: 'contactInformation.email.value', fieldName: 'email' },
        { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
        { fetcher: () => true, fieldName: 'marketingagreement' },
      ],
    });

    await router.push({
      name: 'onlineAgreement.legalRequirements',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e) {
    validating.value = false;
    personalData.value.permanentAddress.setManually.value = true;

    if (isChild.value) {
      legalRepresentative.value.permanentAddress.setManually.value = true;
    }

    if (personalData.value.specifyMailingAddress.value) {
      personalData.value.mailingAddress.setManually.value = true;
    }

    scrollToError();

    console.warn(`There was a validation legalRequirementsError: ${e}`);
  }
}

watch(() => personalData.value.citizenship.value, (newVal) => {
  if (newVal !== null && newVal !== 'cz') {
    showModalForForeigners.value = true;
  }
});

watch(() => legalRepresentative.value.citizenship.value, (newVal) => {
  if (newVal !== null && newVal !== 'cz') {
    showModalForForeigners.value = true;
  }
});

watch(() => personalData.value.permanentAddress.country.value, (newVal) => {
  if (newVal !== null && newVal !== 'cz') {
    showModalForForeigners.value = true;
  }
});

watch(() => legalRepresentative.value.permanentAddress.country.value, (newVal) => {
  if (newVal !== null && newVal !== 'cz') {
    showModalForForeigners.value = true;
  }
});

watch(() => personalData.value.specifyMailingAddress.value, (newVal) => {
  if (newVal === false) {
    personalData.value.mailingAddress.query.value = '';
    mailingAddress.value = null;
    personalData.value.mailingAddress.street.value = '';
    personalData.value.mailingAddress.streetNumber.value = '';
    personalData.value.mailingAddress.city.value = '';
    personalData.value.mailingAddress.zip.value = '';
    personalData.value.mailingAddress.editable.value = true;
  }
});

watch(preferConservativeFund, (newVal) => {
  if (newVal === true) {
    resetPensionQuestionnaire();
  }
});

onBeforeMount(() => {
  if (route.query.bankId === 'rejected') {
    const { online } = route.query;

    router.push({
      name: 'onlineAgreement.personalDataEntryMethodSelection',
      params: { contractUuid: route.params.contractUuid },
      query: {
        bankId: 'rejected',
        online,
      },
    });
  } else {
    loadBankIdFields(store.$state);
  }
});

onMounted(() => {
  if (!isBankIdEntry.value) {
    copyContactNameToPersonalData();
  }

  showCitizenshipModal();
  showLeavingModal();
});
</script>

<style scoped lang="scss">
.personal-data {
  &__modal {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: .3rem;

      span {
        text-align: left;
        width: 30%;
      }
    }
  }
}

.disabled {
  color: #8397b3;
}
</style>
