<template>
  <div dusk="thank-you">
    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm" />
    </div>
    <div class="container--sm my-30">
      <div v-if="!contractIsSigned && !fileNotFound && isBankId">
        <div class="flex flex-center mb-20 flip text-center my-20">
          <img
            :src="getIconUrl('icon-loader_blue')"
            alt="Načítání..."
            class="spin"
          >
        </div>

        <h3 class="text-center">
          Dochází ke zpracování nové smlouvy
        </h3>
      </div>

      <TheHeading v-if="!isBankId">
        Děkujeme vám za sjednání žádosti o výplatu dávky
      </TheHeading>
      <TheHeading v-if="isBankId && contractIsSigned">
        Žádost o výplatu dávky máme od vás elektronicky podepsanou
      </TheHeading>
    </div>

    <div class="container--sm mb-20">
      <h3
        v-if="!isBankId"
        class="my-20"
      >
        Stáhněte a vytiskněte žádost o výplatu dávky.
      </h3>
      <h3 v-if="isBankId && contractIsSigned">
        Odkaz k podpisu žádosti byl klientovi odeslán.
      </h3>

      <div v-if="isBankId && contractIsSigned">
        <p class="mb-20">
          Nyní byl odkaz na dokončení podpisu této žádosti přes Bank iD odeslán
          klientovi na e-mail: <strong>{{ contactInformation.email.value }}</strong>.
          Jakmile bude smlouva klientem podepsána, přijde oběma stranám elektronické potvrzení.
        </p>
      </div>

      <div v-else-if="isCsobDistribution && !isBankId">
        <p class="mb-20">
          <strong>Žádost o výplatu dávky</strong> 2x vytiskněte a v závislosti
          na výši naspořené částky na smlouvě klienta postupujte
          dle následujících pravidel.
        </p>

        <p>
          Naspořená částka je:
        </p>

        <ul class="text-left">
          <li>
            <strong>menší nebo rovna 150 000 Kč</strong> – podepíše klient a jeden obchodní
            zástupce s patřičným oprávněním
          </li>
          <li>
            <strong>větší než 150 000 Kč a zároveň menší nebo rovna 300 000 Kč</strong> – podepíše
            klient, jeden obchodní zástupce s patřičným oprávněním
            a druhý obchodní zástupce
          </li>
          <li>
            <strong>větší než 300 000 Kč</strong> – podepíše klient a jeden
            obchodní zástupce s patřičným oprávněním,
            zároveň je potřeba jedno vyhotovení nechat úředně ověřit.
            Podpis můžete nechat ověřit bezplatně na pobočce ČSOB,
            nebo s poplatkem na pobočkách České pošty (Czech point),
            na obecním úřadě či u notáře.
          </li>
        </ul>
        <p>
          Jedno podepsané vyhotovení/Úředně ověřené vyhotovení nebo další doplňující dokumenty
          získané do klienta (rozsudek soudu, plná moc, rodný list,…)
          bez odkladu odešlete do sídla Penzijní společnosti,
          druhé podepsané vyhotovení předejte klientovi.
        </p>
      </div>

      <div v-else-if="!isCsobDistribution && !isBankId">
        <p>
          <strong>Žádost o výplatu dávky</strong> 2x vytiskněte, podepište
          Vy i klient a jedno vyhotovení
          nechte úředně ověřit. Podpis můžete nechat ověřit bezplatně na pobočce ČSOB,
          nebo s poplatkem na pobočkách České pošty (Czech point), na obecním úřadě či u notáře.
        </p>
        <p>
          Úředně ověřené vyhotovení nebo další doplňující
          dokumenty získané do klienta (rozsudek soudu, plná moc, rodný list,…)
          bez odkladu odešlete do sídla Penzijní společnosti, druhé vyhotovení předejte klientovi.
        </p>

        <p v-if="beforeStart.isTiedAgent.value && !hasClientEmail">
          Pro klienta <strong>vytiskněte dokument Informace o Vázaném zástupci</strong> a předejte mu jej společně s kopií žádosti.
        </p>
      </div>

      <div v-if="fileNotFound && isBankId">
        <div class="mb-30 chart-img my-20 text-center flex-center">
          <img
            :src="getIconUrl('icon-state_danger')"
            alt="chyba-500"
          >
        </div>
        <h3 class="text-danger text-center">
          Došlo k chybě při podpisu nové smlouvy<span>, prosím uzavřete ji znovu.</span>
        </h3>
      </div>

      <div
        v-if="store.beforeStart.isTiedAgent.value && isBankId && contractIsSigned"
        class="container--sm mb-16"
      >
        <hr class="py-20 mt-20">

        <h3 class="text-center">
          A zde jsou údaje klienta, pro kterého byla žádost uzavřena
        </h3>

        <table class="recapitulation-table mb-16">
          <tbody>
            <tr>
              <td>Číslo smlouvy</td>
              <td
                colspan="2"
              >
                {{ store.currentContractInformation.contractNumber.value }}
              </td>
            </tr>
            <tr>
              <td>Rodné číslo klienta</td>
              <td
                colspan="2"
              >
                {{ store.personalData.personalIdNumber.value }}
              </td>
            </tr>
            <tr>
              <td>Jméno a přijmení klienta</td>
              <td
                colspan="2"
              >
                {{ store.personalData.firstName.value }} {{ store.personalData.lastName.value }}
              </td>
            </tr>
            <tr>
              <td>Typ úkonu</td>
              <td
                v-if="store.currentContractInformation.isAdditionalPensionSaving.value"
                colspan="2"
              >
                3 - Žádost o&nbsp;výplatu dávky z&nbsp;doplňkového penzijního spoření u&nbsp;ČSOB&nbsp;PS
              </td>
              <td
                v-else
                colspan="2"
              >
                7 - Žádost o&nbsp;výplatu dávky z&nbsp;Transformovaného fondu Stabilita ČSOB&nbsp;PS
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="loading && !isBankId">
        <div class="flex flex-center mb-20 flip">
          <img
            :src="getIconUrl('icon-loader_blue')"
            alt="Načítání..."
            class="spin"
          >
        </div>
        <div class="text-loading text-center text-bold">
          Nahráváme ke stažení uzavřenou žádost o ukončení smlouvy v PDF, chvíli strpení
        </div>
      </div>

      <LitAlert
        v-if="showContractHiddenAlert && !isBankId"
        class="mb-20"
        alert-type="info"
        dusk="contract-sending-failure"
      >
        <div
          v-if="showContractHiddenAlert && !isBankId"
          class="mb-20"
          dusk="contract-sending-failure"
        >
          Odkaz ke stažení žádosti o ukončení smlouvy již není platný
          nebo jste ji stáhli již {{ submitResponse.value?.metadata.maxDownloadCount }}x.
          Výpověď však <span v-if="!beforeStart.isTiedAgent.value">máte Vy i klient</span><span v-else>má klient</span> v e-mailu.
        </div>
      </LitAlert>

      <LitAlert
        v-if="beforeStart.isTiedAgent.value && !showContractHiddenAlert"
        alert-type="warning"
        class="my-20"
      >
        <strong>Dokumentace již není odesílána na e-mail obchodníka.</strong> V případě ukončení procesu/zavření
        tohoto okna,
        už nebude možné dokumentaci znovu vytisknout.
      </LitAlert>

      <div
        v-if="terminationPdf && remainingAttempts > 0"
        class="flex flex-center gap-1"
      >
        <LitButton
          download
          :href="terminationPdf.link"
          as="a"
          @click="downloaded++"
        >
          <template #before>
            <Download :size="20" />
          </template>

          <template #default>
            <div class="flex flex-dir-column">
              <span class="text-uppercase">Výpověď</span>
              <span class="text-sm font-weight-light">PDF: {{ terminationPdf.size }} kB</span>
            </div>
          </template>
        </LitButton>

        <LitButton
          v-if="beforeStart.isTiedAgent.value && !hasClientEmail"
          download
          href="/storage/documents/informace_o_vazanem_zastupci_cp.pdf"
          as="a"
          target="_blank"
        >
          <template #before>
            <Download :size="20" />
          </template>

          <template #default>
            <div class="flex flex-dir-column">
              <span class="text-uppercase">Informace o vázaném zástupci</span>
            </div>
          </template>
        </LitButton>
      </div>

      <div
        v-if="terminationPdf && remainingAttempts > 0"
        class="my-20"
      >
        Odkaz ke stažení výpovědi ve formátu PDF je
        <strong>platný do {{ accessTokenValidity && useDateTimeFormat(accessTokenValidity) }}</strong>,
        výpověď můžete stáhnout {{ remainingAttempts }}x.
      </div>

      <hr class="py-20 mt-20">

      <div class="flex flex-center">
        <LitButton
          variant="outline"
          :href="getFormHomeUrl()"
          as="a"
        >
          <span>
            Zpět na hlavní rozcestník
          </span>
        </LitButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import { Download } from 'lucide-vue-next';
import env from '@/js/env';
import contractFiles from '@/js/api/contractFiles';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import { draftTokens } from '@/js/api';
import { getIconUrl, scrollToError, waitForSignedPdf } from '@/js/utils';

import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat';
import { useTerminationStore } from '@/js/stores';
import { resetState } from '@/js/stores/utils';
import TheHeading from '@/js/components/TheHeading.vue';
import type { File } from '@/js/stores/types';

const route = useRoute();
const router = useRouter();

const store = useTerminationStore();
const { validateFields } = store;
const {
  isBankId,
  contactInformation,
  isCsobDistribution,
  submitResponse,
  draftToken,
  hasClientEmail,
  beforeStart,
} = storeToRefs(store);

const terminationPdf = ref<null | { link: string, size: number }>(null);
const loading = ref(true);
const error = ref(false);
const validating = ref(false);
const now = ref(new Date());
const currentTimeInterval = ref<number | null>(null);
const downloaded = ref(0);
const contractIsSigned = ref(false);
const fileNotFound = ref(false);

onBeforeRouteLeave((to, _from, next) => {
  resetState(store.$state, false);

  const disallowedPaths = [
    'termination.recapitulation',
  ];

  if (to.name && disallowedPaths.includes(to.name.toString())) {
    next({ name: 'distribution.home' });
  } else {
    next();
  }
});

const remainingAttempts = computed(() => {
  return (submitResponse.value.value?.metadata.maxDownloadCount || 0) - downloaded.value;
});

const accessTokenValidity = computed(() => {
  const tokenValidity = submitResponse.value.value?.metadata.accessTokenValidity;
  return tokenValidity ? new Date(tokenValidity) : null;
});

const isTokenValid = computed(() => {
  if (accessTokenValidity.value) {
    return accessTokenValidity.value > now.value;
  }

  return false;
});

const showContractHiddenAlert = computed(() => {
  return !isTokenValid.value || remainingAttempts.value <= 0;
});

async function existsDraftToken (signMethod: string) {
  const { contractUuid } = route.params;
  const { data } = await draftTokens.show(contractUuid as string, signMethod);

  return data.draftToken !== null;
}

async function sendDraftToken (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    validating.value = false;

    const { data } = await draftTokens.create(contractUuid as string, signMethod);

    draftToken.value.value.value = data.draftToken;
    draftToken.value.value.expiresAt = data.validTo;

    await router.push({
      name: 'termination.thankYouPage',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}

function getFormHomeUrl () {
  return `${window.location.origin}/distribuce`;
}

function waitForTerminationPdf () {
  let attempts = 0;

  const interval = setInterval(async () => {
    const contractId = submitResponse.value.value?.metadata.contractId;
    const files: File[] = await contractFiles.index(contractId);

    attempts += 1;

    const file = files.find((file) => file.file_type === 'contract');

    if (files.length > 0 && file) {
      terminationPdf.value = {
        link: `${env.REST_API_URI}/v1/files/${file.id}?token=${submitResponse.value.value?.metadata.accessToken}&contractId=${contractId}`,
        size: Math.round(file.file_size / 1024),
      };

      clearInterval(interval);
    } else if (attempts > 10) {
      clearInterval(interval);
    }
  }, 3 * 1000);
}

function startTimeMeasuring () {
  currentTimeInterval.value = setInterval(() => {
    now.value = new Date();
  }, 1000);
}

watch(terminationPdf, (newValue) => {
  if (newValue !== null) {
    loading.value = false;
  }
});

watch(isTokenValid, (newValue) => {
  if (!newValue) {
    currentTimeInterval.value && clearInterval(currentTimeInterval.value);
    terminationPdf.value = null;
  }
});

onMounted(async () => {
  const { contractUuid } = route.params;

  waitForTerminationPdf();
  startTimeMeasuring();

  if (isBankId.value) {
    try {
      await waitForSignedPdf(contractUuid as string, 'termination', 6);
      contractIsSigned.value = true;

      if (!await existsDraftToken('bankId')) {
        await sendDraftToken('bankId');
      }
    } catch (e) {
      console.warn(e);

      contractIsSigned.value = false;
      fileNotFound.value = true;
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.thanksAlert {
  margin-bottom: 0;
  font-size: 1.375rem;
}

.grey {
  color: getColor(grey);
}

.download {
  text-decoration: none;
  align-items: center;
}

.rotate {
  transform: rotate(180deg);
}

.big {
  height: 70px;
}

.text-loading {
  color: #092f68;
}
</style>
