import axios from './axios';

export function getList (contractUuid: string): Promise<{ data: { banks: string[] } }> {
  return axios.get('/api/v1/availableBanks', {
    params: { contractUuid },
  });
}

export default {
  getList,
};
