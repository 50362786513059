<script>
import { h } from 'vue';

import FUNDS from '../data/fundsData';
import LitTabs from './Base/Tabs/LitTabs.vue';
import LitTab from './Base/Tabs/LitTab.vue';
import LitAlert from './Base/LitAlert.vue';
import LitInput from './Base/LitInput.vue';
import LitModal from './Base/LitModal.vue';
import FundsChart from './FundsChart.vue';
import LifeCycleStrategyDetail from './LifeCycleStrategyDetail.vue';

const MAX_SHARE = 100;

export default {
  components: {
    LitTabs,
    LitTab,
    LitAlert,
    LitInput,
    LitModal,
    FundsChart,
    LifeCycleStrategyDetail,
  },

  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: null,
    },

    fundCombination: {
      type: Object,
      required: false,
      default: null,
    },

    preferConservativeFund: {
      type: Boolean,
      required: false,
      default: false,
    },

    meetReinvestmentCondition: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
    'update:fundCombination',
  ],

  data: () => ({
    fundCombinationInt: {
      esg: 0,
      dynamic: 0,
      balanced: 0,
      pension: 0,
      conservative: 0,
    },
    fundsData: FUNDS,
    showStrategyDetail: null,
  }),

  computed: {
    sumFunds () {
      const sum = Object.values(this.fundCombinationSync)
        .reduce((a, b) => a + b);

      return Number.parseInt(sum, 10);
    },

    /**
     * For every fund, calculates the remaining max share.
     * 100 - (current_sum - current_share)
     */
    remainingParts () {
      const sum = this.sumFunds;
      const funds = this.fundCombinationSync;

      const result = {};

      Object.entries(funds).forEach(([key, share]) => {
        result[key] = MAX_SHARE - Number.parseInt(sum - share, 10);
      });

      return result;
    },

    selectedStrategy: {
      get () {
        return this.modelValue;
      },

      set (val) {
        this.$emit('update:modelValue', val);
      },
    },

    fundCombinationSync: {
      get () {
        return this.fundCombination === null
          ? this.fundCombinationInt
          : this.fundCombination;
      },

      set (val) {
        if (this.fundCombination === null) {
          this.fundCombinationInt = val;
        } else {
          this.$emit('update:fundCombination', val);
        }
      },
    },
  },

  methods: {
    updateFundCombination (fundName, value) {
      const funds = { ...this.fundCombinationSync }; // creates copy
      funds[fundName] = Number.parseInt(value || 0, 10);
      this.fundCombinationSync = funds;
    },

    incrementShare (fund, val = 5) {
      this.updateFundCombination(fund, this.fundCombinationSync[fund] + val);
    },

    decrementShare (fund, val = 5) {
      this.updateFundCombination(fund, this.fundCombinationSync[fund] - val);
    },

    hideStrategyDetail (show) {
      if (show === false) {
        this.showStrategyDetail = null;
      }
    },

    roundParts () {
      const funds = this.fundCombinationSync;

      Object.entries(funds).forEach(([key, share]) => {
        const decrease = share % 5;
        funds[key] = share - decrease;
      });
    },
  },

  render () {
    const createTab = ({
      title,
      slug,
      content,
      label,
      labelType,
      dusk,
      labelIcon,
    }) => (
      h(
        LitTab,
        {
          title,
          label,
          'label-type': labelType,
          dusk,
          labelIcon,
          'data-slug': slug,

        },
        () => [
          h('p', [content.description]),
          h('button', {
            class: 'btn-simple',

            onClick: () => {
              this.showStrategyDetail = slug;
            },
          }, [content.detailLink]),
        ],
      ));

    const createFundOption = ({ title, slug, content }) => (
      h(
        LitInput,
        {
          'min': 0,
          'max': this.remainingParts[slug],
          'showAppend': true,
          'step': 5,
          'modelValue': this.fundCombinationSync[slug],
          'label': title,
          'className': 'control--sm',
          'type': 'number',
          'class': 'mb-20',

          'onBlur': () => {
            this.roundParts();
          },

          'onUpdate:modelValue': (event) => {
            this.updateFundCombination(slug, event);
          },
        },
        {
          'append': () => h('span', {
            class: 'unit',
          }, '%'),

          'number-actions': () => h('div', {
            class: 'number-actions',
          }, [
            h('button', {
              class: 'number-actions__btn',
              type: 'button',
              onClick: () => {
                this.incrementShare(slug);
              },
            }, '+'),

            h('button', {
              class: 'number-actions__btn',
              type: 'button',
              onClick: () => {
                this.decrementShare(slug);
              },
            }, '-'),
          ]),

          'actions': () => h('div', {
            class: 'actions-group',
          }, [
            h('button', {
              class: 'btn-simple',
              type: 'button',
              onClick: () => {
                this.showStrategyDetail = slug;
              },
            }, content.detailLink),
          ]),
        },
      )
    );

    const fundCombinationOptions = [
      createFundOption(FUNDS.esg),
      createFundOption(FUNDS.dynamic),
      createFundOption(FUNDS.balanced),
      createFundOption(FUNDS.pension),
      createFundOption(FUNDS.conservative),
    ];

    const customFundsCombination = h(
      LitTab,
      {
        'title': 'Vlastní kombinace fondů',
        'label': null,
        'data-slug': 'customFundCombination',
      },
      () => [
        h('div', {
          class: 'custom-strategy',
        }, 'Podíl v %'),

        ...fundCombinationOptions,

        h('hr'),

        h('div', {
          class: 'custom-strategy-sum my-20',
        }, [
          h('div', {
            class: 'custom-strategy-sum__label',
          }, 'Celkem:'),

          h('div', {
            class: 'custom-strategy-sum__sum',
          }, [
            h(
              'span',
              {
                class: [this.sumFunds === 100 ? 'text-success' : 'text-danger', 'text-bold'],
              },
              `${this.sumFunds} % `,
            ),
            'ze 100 %',
          ]),
        ]),

        this.sumFunds < 100
          ? h(LitAlert, {
            alertType: 'danger',
          }, () => [
            'Vámi zadaná hodnota musí činit ',
            h('span', {
              class: 'text-bold',
            }, 'v součtu 100%'),
            '. Pro pokračování vložte chybějící hodnotu.',
          ])
          : null,
      ],
    );

    const children = [];

    if (this.preferConservativeFund) {
      children.push(createTab(FUNDS.preferredConservativeFund));

      if (this.meetReinvestmentCondition) {
        children.push(createTab(FUNDS.reinvestment));
        children.push(customFundsCombination);
      } else {
        children.push(customFundsCombination);
        children.push(createTab(FUNDS.reinvestment));
      }
    } else if (this.meetReinvestmentCondition) {
      FUNDS.reinvestment.label = 'Doporučujeme';
      FUNDS.reinvestment.labelType = 'warning';
      FUNDS.reinvestment.labelIcon = 'tag-star_white';
      FUNDS.lifeCycle.label = null;
      FUNDS.lifeCycle.labelType = null;
      FUNDS.lifeCycle.labelIcon = null;

      children.push(createTab(FUNDS.reinvestment));
      children.push(customFundsCombination);
      children.push(createTab(FUNDS.lifeCycle));
    } else {
      FUNDS.lifeCycle.label = 'Doporučujeme';
      FUNDS.lifeCycle.labelType = 'warning';
      FUNDS.lifeCycle.labelIcon = 'tag-star_white';
      FUNDS.reinvestment.label = null;
      FUNDS.reinvestment.labelType = null;
      FUNDS.reinvestment.labelIcon = null;

      children.push(createTab(FUNDS.lifeCycle));
      children.push(customFundsCombination);
      children.push(createTab(FUNDS.reinvestment));
    }

    const content = [
      h(LitTabs, {
        'returnAttribute': 'data-slug',
        'modelValue': this.modelValue,

        'onUpdate:modelValue': (event) => {
          this.selectedStrategy = event;
        },
      }, () => children),
    ];

    const fundDetailBody = [];

    if (this.showStrategyDetail === 'lifeCycle') {
      fundDetailBody.push(
        h(LifeCycleStrategyDetail),
      );
    } else {
      fundDetailBody.push(
        h('FundsChart', {
          fundType: this.showStrategyDetail,
          class: 'mb-30',
        }),

        h('p', {
          innerHTML: FUNDS[this.showStrategyDetail]?.content?.description,
        }),
      );
    }

    if (this.showStrategyDetail !== null) {
      content.push(h(
        LitModal,
        {
          'modelValue': this.showStrategyDetail !== null,
          'max-width': 838,
          'onUpdate:modelValue': (show) => {
            if (show === false) {
              this.showStrategyDetail = null;
            }
          },
        },
        {
          header: () => h('h3', {
            class: 'text-left',
            innerHTML: FUNDS[this.showStrategyDetail].title,
          }),

          body: () => fundDetailBody,

          footer: () => h('div', {
            class: 'modal__buttons flex-end',
          }, [
            h('button', {
              class: 'btn btn-primary btn-outline',
              dusk: 'close-modal',
              onClick: () => {
                this.showStrategyDetail = null;
              },
              innerHTML: 'Zavřít',
            }),
          ]),
        },
      ));
    }

    return h('div', content);
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.number-actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;

  .number-actions__btn {
    height: math.div($input-height, 2);
    width: math.div($input-height, 2);
    color: getColor(light-blue);
    outline: none;
    font-size: $root;

    &:hover {
      background: getColor(light-blue);
      color: getColor(white);
    }
  }
}

.actions-group {
  @include media(max, $md) {
    max-width: 220px;
    margin-left: inherit;
    text-align: right;

    button {
      display: block;
      text-align: right;
    }
  }
}

.control.control--unit {
  input[type=number] {
    padding-right: 50px;
    font-weight: $bold;
  }

  .unit {
    right: math.div($input-height, 2);
    width: 25px;
    font-weight: $bold;
    color: inherit;
  }
}

.custom-strategy {
  font-weight: $bold;
  margin-bottom: 10px;

  @include media(min, $md) {
    max-width: 364px;
    text-align: center;
  }

  &-sum {
    display: flex;

    @include media(max, $md) {
      max-width: 220px;
      justify-content: space-between;
    }

    &__label {
      margin-right: 10px;
      font-weight: $bold;

      @include media(min, $md) {
        flex-basis: 177px;
      }
    }

    &__sum {
      text-align: right;

      @include media(min, $md) {
        flex-basis: 177px;
        justify-content: flex-end;
      }
    }

    &__title {
      font-weight: $bold;
    }
  }
}
</style>
