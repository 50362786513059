<template>
  <div dusk="signing-person">
    <div
      v-if="showSignerPersonalData"
      dusk="signer-information"
    >
      <h3>
        <LitIconSvg
          v-if="!isParticipant"
          class="mr-5"
          icon-name="ico_signatory"
        />

        Osobní údaje podepisující osoby
      </h3>

      <ServerValidatedFormField
        v-slot="{ value, input, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.firstName`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Jméno"
          name="signer-first-name"
          dusk="signer-first-name"
          :model-value="value"
          :disabled="true"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.lastName`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Příjmení"
          name="signer-last-name"
          dusk="signer-last-name"
          :model-value="value"
          :disabled="true"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.citizenship`"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitSelect
          class="mb-20"
          label="Státní občanství"
          dusk="signer-citizenship"
          :model-value="value"
          :options="citizenshipOptions"
          :error="errors.length > 0 ? errors[0] : ''"
          :searchable="true"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.countryOfBirth`"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitSelect
          class="mb-20"
          label="Stát narození"
          dusk="signer-country-of-birth"
          :options="countryOfBirthOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          :searchable="true"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.birthplace`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          name="signer-birth-place"
          label="Místo narození"
          dusk="signer-birth-place"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.permanentAddressArea`"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitRadio
          class="mb-20"
          label="Trvalé bydliště"
          dusk="signer-permanent-address-area"
          name="signer-permanent-address-area"
          :options="permanentAddressAreaOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <AddressEntry
        label="Trvalá adresa"
        name="signer-permanent-address"
        dusk="signer-permanent-address"
        :namespace="namespace"
        store-path="signer.permanentAddress"
        :show-alert="true"
        :allow-autocomplete="!signerPermanentStayOutsideCzechia"
        :country-options="signerCountryOptions"
        :searchable-country="signerPermanentStayOutsideCzechia"
        :validate-fnc="validateFnc"
      >
        <template #autocomplete-actions>
          <button
            type="button"
            class="btn-simple"
            @click="copyChildAddress"
          >
            Shodná s trvalou adresou klienta
          </button>
        </template>
      </AddressEntry>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.personalIdNumber`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          name="signer-personal-id"
          dusk="signer-personal-id"
          :label="signerPermanentAddressArea === 'cz'
            ? 'Rodné číslo'
            : 'Číslo pojištěnce zdr. pojišťovny'"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
          @keydown.space.prevent
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, errors, input, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.birthDate`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          v-if="signerBirthDay !== undefined && !signerPermanentStayOutsideCzechia"
          class="mb-20"
          name="signer-birth-day"
          label="Datum narození"
          dusk="signer-birthday"
          :model-value="value"
          :errors="errors"
          :read-only="true"
          @update:model-value="input"
          @change="validate"
        />
        <DatePicker
          v-else
          class="mb-20"
          name="signer-birth-day"
          label="Datum narození"
          dusk="signer-birthday"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @change="validate"
        />
      </ServerValidatedFormField>

      <div dusk="signer-document-data">
        <h3>Doklad totožnosti</h3>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          :namespace="namespace"
          :field-path="`${storePath}.document.type`"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitSelect
            class="mb-20"
            label="Typ dokladu"
            name="signer-document-type"
            dusk="signer-document-type"
            :debounce="0"
            :options="documentTypes"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.document.name`"
          @validate="validateFnc"
        >
          <LitInput
            v-show="signerDocumentType === 'anotherDoc'"
            class="mb-20"
            label="Druh průkazu totožnosti"
            name="signer-document-name"
            dusk="signer-document-name"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.document.number`"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitInput
            class="mb-20"
            label="Číslo dokladu"
            name="signer-document-number"
            dusk="signer-document-number"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.document.validUntil`"
          :debounce="0"
          @validate="validateFnc"
        >
          <DatePicker
            class="mb-20"
            label="Platný do"
            name="signer-document-valid"
            dusk="signer-document-valid"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @change="validate"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.document.issuedBy`"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitInput
            class="mb-30"
            label="Vydal"
            name="signer-document-issued"
            dusk="signer-document-issued"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>
      </div>

      <div dusk="legal-legislative-data">
        <h3>Legislativní údaje</h3>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          :namespace="namespace"
          :field-path="`${storePath}.isPoliticallyExposed`"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitRadio
            class="mb-20"
            tooltip="Politicky exponovaná osoba je osoba ve významné veřejné funkci
            nebo fyzická osoba s takovou osobou příbuzná či v partnerském vztahu."
            dusk="signer-politically-exposed"
            name="legalRepresentativePoliticallyExposedPerson"
            label="Je podepisující osoba politicky exponovanou osobou?"
            :show-inline="true"
            :options="politicallyExposedOptions"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          />
        </ServerValidatedFormField>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { format } from 'date-fns';
import { getActivePinia } from 'pinia';
import { get } from 'lodash';

import LitInput from '@/js/components/Base/LitInput.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import DatePicker from '@/js/components/DatePicker.vue';
import AddressEntry from '@/js/components/AddressEntry.vue';

import countriesOfEU from '@/js/data/countriesOfEU';
import { countriesOfBirth } from '@/js/data/countryOfBirthOptions';
import { citizenshipCountries } from '@/js/data/citizenshipOptions';
import { personalIdNumber } from '@/js/utils';
import type { ValidateStoreParamsFnc } from '@/js/stores/utils';
import { copyAddress, copyFields, setStoreFields, syncedStoreField } from '@/js/stores/utils';

interface Props {
  namespace: string
  storePath: string
  copyAddressSource: string
  copyNamesSource: string
  validateFnc: ValidateStoreParamsFnc
}

const props = defineProps<Props>();

const isSignerAddressSameAsClients = ref(false);
const countryOfBirthOptions = ref(countriesOfBirth);
const citizenshipOptions = ref(citizenshipCountries);

const dateFormat = ref('dd. MM. yyyy');

const documentTypes = shallowRef([
  { value: 'idCard', label: 'Občanský průkaz' },
  { value: 'passport', label: 'Cestovní pas' },
  { value: 'anotherDoc', label: 'Jiný doklad' },
]);

const politicallyExposedOptions = ref([
  { value: false, label: 'Ne, není' },
  { value: true, label: 'Ano, je' },
]);

const permanentAddressAreaOptions = shallowRef([
  { value: 'cz', label: 'v ČR' },
  { value: 'eu', label: 'v EU' },
  { value: 'other', label: 'mimo EU' },
]);

const activeStore = computed(() => {
  const stores = getActivePinia();

  if (!stores) {
    throw new Error('No active pinia stores found');
  }

  const store = get(stores.state.value, props.namespace);

  if (!store) {
    throw new Error(`No store found for namespace ${props.namespace}`);
  }

  return store;
});

const isClientFromCzechia = computed(() => {
  return activeStore.value.personalData.permanentAddressArea.value === 'cz';
});

const isParticipant = computed(() => {
  return activeStore.value.signer.type.value === 'participant';
});

const signerPersonalIdNumber = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.personalIdNumber.value`,
  }),
});

const signerType = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.type.value`,
  }),
});

const showSignerPersonalData = computed(() => {
  return (signerType.value !== '' && !isParticipant.value);
});

const signerPermanentAddressArea = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.permanentAddressArea.value`,
  }),
});

const signerPermanentStayOutsideCzechia = computed(() => {
  return signerPermanentAddressArea.value !== 'cz';
});

const signerBirthDay = computed(() => {
  try {
    return personalIdNumber(signerPersonalIdNumber.value)?.birthDay;
  } catch (e) {
    console.warn(e);

    return undefined;
  }
});

const countriesOfEUWithoutCzechia = computed(() => {
  return countriesOfEU.filter((country) => country !== 'cz');
});

const signerCountryOptions = computed(() => {
  if (signerPermanentAddressArea.value === 'other') {
    return countryOfBirthOptions.value.filter(
      (country) => !(countriesOfEU.includes(country.value) || country.value === ''),
    );
  }

  if (signerPermanentAddressArea.value === 'eu') {
    return countryOfBirthOptions.value.filter(
      (country) => countriesOfEUWithoutCzechia.value.includes(country.value),
    );
  }

  if (signerPermanentAddressArea.value === 'cz') {
    return countryOfBirthOptions.value.filter(
      (country) => country.value === 'cz',
    );
  }

  return countryOfBirthOptions.value;
});

const signerBirthDate = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.birthDate.value`,
  }),
});

const signerBirthDateErrors = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.birthDate.errors`,
  }),
});

const signerPersonalIdNumberErrors = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.personalIdNumber.errors`,
  }),
});

const signerStateOfPermanentAddress = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.permanentAddress.country.value`,
  }),
});

const signerDocumentType = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.document.type.value`,
  }),
});

const clientsPermanentAddressArea = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.copyAddressSource}.permanentAddressArea.value`,
  }),
});

function copySignerNamesFromContactInformation () {
  copyFields(activeStore.value, [
    {
      pathFrom: `${props.copyNamesSource}.firstName.value`,
      pathTo: `${props.storePath}.firstName.value`,
    },
    {
      pathFrom: `${props.copyNamesSource}.lastName.value`,
      pathTo: `${props.storePath}.lastName.value`,
    },
  ]);
}

function copyChildAddress () {
  isSignerAddressSameAsClients.value = true;

  setStoreFields(activeStore.value, [
    {
      path: `${props.storePath}.permanentAddressArea.value`,
      value: clientsPermanentAddressArea.value,
    },
    {
      path: `${props.storePath}.permanentAddress.setManually.value`,
      value: isClientFromCzechia.value ? `${props.copyAddressSource}.permanentAddress.setManually.value` : true,
    },
    {
      path: `${props.storePath}.permanentAddress.editable.value`,
      value: false,
    },
  ]);

  copyAddress(activeStore.value, {
    source: `${props.copyAddressSource}.permanentAddress`,
    destination: `${props.storePath}.permanentAddress`,
  });
}

function clearSignerNames () {
  setStoreFields(activeStore.value, [
    {
      path: `${props.storePath}.firstName.value`,
      value: '',
    },
    {
      path: `${props.storePath}.lastName.value`,
      value: '',
    },
  ]);
}

watch(signerPermanentAddressArea, (newVal) => {
  switch (newVal) {
    case 'cz':
      signerStateOfPermanentAddress.value = 'cz';
      signerPersonalIdNumber.value = '';
      break;

    case 'eu':
      signerStateOfPermanentAddress.value = isSignerAddressSameAsClients.value === true
        ? ''
        : signerStateOfPermanentAddress.value = '';
      signerPersonalIdNumberErrors.value = [];
      signerPersonalIdNumber.value = '';
      break;

    case 'other':
      signerStateOfPermanentAddress.value = isSignerAddressSameAsClients.value === true
        ? ''
        : signerStateOfPermanentAddress.value = '';
      signerPersonalIdNumberErrors.value = [];
      signerPersonalIdNumber.value = '';
      break;

    default:
      signerStateOfPermanentAddress.value = 'cz';
  }
});

watch(signerBirthDay, (newValue) => {
  if (!signerPermanentStayOutsideCzechia.value && newValue !== undefined) {
    try {
      signerBirthDateErrors.value = [];
      signerBirthDate.value = format(newValue, dateFormat.value);
    } catch (e) {
      console.warn(e);
    }
  }
});

onMounted(() => {
  clearSignerNames();

  if (!isParticipant.value) {
    copySignerNamesFromContactInformation();
  }
});
</script>
