<template>
  <div dusk="thank-you">
    <div class="benefits bg-blue pt-30 pb-10" />

    <div class="container--sm my-30">
      <TheHeading>Děkujeme vám za sjednání penzijního spoření</TheHeading>
      <p>
        Gratulujeme k úspěšnému odeslání žádosti o penzijní spoření.
        <span v-if="store.beforeStart.isTiedAgent.value">Veškeré dokumenty k nové smlouvě a další pokyny zasíláme klientovi na e-mailovou adresu.</span>
        <span v-else>Veškeré dokumenty k nové smlouvě a další pokyny zasíláme vám i klientovi na e-mailovou adresu.</span>
      </p>

      <hr class="py-20 mt-20">
    </div>

    <div class="container--sm">
      <h3 class="text-center">
        Provedení platby pomocí QR kódu
      </h3>
      <div class="payment-info">
        <div class="div-table__row mb-10">
          <div class="qr-code">
            <LitIconSvg
              v-if="loading"
              class="spin flip"
              icon-name="icon-loader"
            />
            <QrcodeVue
              v-show="variableSymbol"
              v-if="qrCodePayment"
              :value="qrCodePayment"
              :size="180"
              level="H"
            />
          </div>
        </div>
      </div>

      <hr class="py-20 mt-20">
    </div>

    <div
      v-if="store.beforeStart.isTiedAgent.value"
      class="container--sm text-center my-30"
    >
      <h3 class="text-center">
        A zde jsou údaje klienta, pro kterého byla smlouva uzavřena
      </h3>

      <table class="recapitulation-table mb-16">
        <tbody>
          <tr>
            <td>Číslo smlouvy</td>
            <td
              colspan="2"
            >
              {{ variableSymbol }}
            </td>
          </tr>
          <tr>
            <td>Rodné číslo klienta</td>
            <td
              colspan="2"
            >
              {{ store.personalData.personalIdNumber.value }}
            </td>
          </tr>
          <tr>
            <td>Jméno a přijmení klienta</td>
            <td
              colspan="2"
            >
              {{ store.personalData.firstName.value }} {{ store.personalData.lastName.value }}
            </td>
          </tr>
          <tr>
            <td>Frekvence placení</td>
            <td
              colspan="2"
            >
              Měsíčně
            </td>
          </tr>
          <tr>
            <td>Částka, kterou si bude klient platit</td>
            <td
              colspan="2"
            >
              {{ store.contributionAndStrategy.contributionAmount.value }} Kč
            </td>
          </tr>
        </tbody>
      </table>

      <hr class="py-20 mt-20">
    </div>

    <div
      v-show="showPensionCompanyNotice"
      class="container--sm my-30"
    >
      <p>
        Vyplňte prosím s klientem také <strong>výpověď doplňkového penzijního spoření</strong>
        se žádostí o převod prostředků. Výpověď se žádostí o převod musí být úředně ověřená
        (matrika/Czech Point/notář) a odeslaná do původní penzijní společnosti.
      </p>

      <div class="text-center mt-20">
        <LitButton
          href="/storage/documents/vypoved_DPS_se_zadosti_o_prevod_k_CSOBPS_512.pdf"
          as="a"
          download
        >
          <template #before>
            <Download :size="20" />
          </template>

          <template #default>
            <div class="flex flex-dir-column">
              <span class="text-uppercase">Stáhnout výpověď</span>
              <span class="text-sm font-weight-light">PDF: 257 kB</span>
            </div>
          </template>
        </LitButton>
      </div>
    </div>

    <div
      v-show="showPensionCompanyNotice"
      class="container--sm my-30 separator"
    />

    <div class="container--sm my-30 flex flex-center">
      <LitButton
        variant="outline"
        :to="{ name: 'distribution.home' }"
        title="Přejít na hlavní rozcestník"
      >
        Přejít na hlavní rozcestník
      </LitButton>
    </div>
  </div>
</template>

<script lang="ts">
import QrcodeVue from 'qrcode.vue';
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import { Download } from 'lucide-vue-next';
import buildPaymentDescription from '@/js/services/buildPaymentDescriptor';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

import { destroy, show } from '@/js/api/contracts';
import { useDistributionStore } from '@/js/stores';
import { resetState } from '@/js/stores/utils';
import TheHeading from '@/js/components/TheHeading.vue';
import LitButton from '@/js/components/Base/LitButton.vue';

export default {
  components: {
    LitButton,
    TheHeading,
    LitIconSvg,
    Download,
    QrcodeVue,
  },

  beforeRouteLeave (to, _from, next) {
    this.clearLocalContractData();

    const disallowedPaths = [
      'distribution.contractDocumentsAndConsents',
    ];

    if (disallowedPaths.includes(to.name as string)) {
      next({ name: 'distribution.home' });
    } else {
      next();
    }
  },

  setup () {
    const contractPdf = ref<string | null>(null);
    const loading = ref(true);
    const now = ref(new Date());
    const currentTimeInterval = ref<number | null>(null);
    const variableSymbol = ref<string | null>(null);

    const store = useDistributionStore();
    const { contactInformation, contractSettings, contributionAndStrategy, personalData, isParticipant } = storeToRefs(store);

    const showPensionCompanyNotice = computed(() => {
      return contractSettings.value.transferExistingRetirementSavings.value === true
        && contractSettings.value.currentContract.type.value === 'pensionCompany';
    });

    const payment = computed(() => {
      return contributionAndStrategy.value.contributionAmount.value;
    });

    const personalIdNumber = computed(() => {
      const personalId = personalData.value.personalIdNumber.value ?? null;

      return personalId === null ? null : personalId.replace('/', '');
    });

    function clearLocalContractData () {
      resetState(store.$state);
    }

    return {
      contractPdf,
      loading,
      now,
      currentTimeInterval,
      variableSymbol,
      showPensionCompanyNotice,
      payment,
      personalIdNumber,
      contactInformation,
      isParticipant,
      store,
      clearLocalContractData,
    };
  },

  computed: {
    constantSymbol () {
      return '3558';
    },

    targetAccount () {
      return '2106990187/2700';
    },

    targetAccountIban () {
      // CZ -> Country code (2 chars)
      // 65 -> check digits (2 chars)
      // bankCode (4 chars)
      // accountNumber (16 chars)

      const account = this.targetAccount.split('/');

      const code = account[1].padStart(4, '0');
      const number = account[0].padStart(16, '0');

      return `CZ65${code}${number}`;
    },

    qrCodePayment () {
      return buildPaymentDescription(
        this.targetAccountIban,
        Number.parseFloat(this.payment.toString()).toFixed(2),
        'CZK',
        'Penzijní spoření',
        this.variableSymbol,
        this.personalIdNumber,
        this.constantSymbol,
      );
    },
  },

  async mounted () {
    this.loading = true;
    const { contractUuid } = this.$route.params;

    try {
      const { data } = await show(contractUuid as string, 'distribution');
      this.variableSymbol = data.contract_number;
      this.loading = false;

      await destroy(contractUuid as string);
    } catch (e) {
      console.warn('There is no Variable symbol or contract has not been deleted!', e);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.bg-green {
  background-color: getColor(bg-green);
  overflow-y: hidden;
}

h3 {
  text-align: center;

  @include media(min, $md) {
    text-align: left;
  }
}

.text-center {
  text-align: center;
}

.short {
  width: 100%;

  @include media(min, $md) {
    width: 50%;
  }
}

.payment-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @include media(min, $md) {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .qr-code {
    flex: 1;

    @include media(min, $md) {
      margin-top: 0;
    }
  }

  .div-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__row {
      display: flex;
    }

    &__cell {
      display: flex;

      &--first {
        flex-basis: 167px;
      }
    }
  }

  span {
    font-weight: $normal;
  }
}

li {
  list-style-image: url('images/icons/dot.svg');
}

.cta {
  background: getColor(cta);
  display: flex;
  padding: 15px;

  &-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    line-height: 1.45;
    width: 100%;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    margin-right: 15px;

    img {
      width: 25px;
    }
  }
}

.separator {
  width: auto;
  height: 1px;
  background-color: getColor(light-grey);
}
</style>
